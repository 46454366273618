import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authRequest, makeAsyncInitialState, makeAsyncReducer } from "./util"
import config from "../config"

const initialState = {
  pingResponse: makeAsyncInitialState({ lastPing: null }),
  users: makeAsyncInitialState({
    users: [],
  }),
  deleteUser: makeAsyncInitialState(),
  homeDetails: makeAsyncInitialState({}),
  otaResponse: makeAsyncInitialState(),
  activeInvites: makeAsyncInitialState({}),
  eventStats: makeAsyncInitialState({}),
  activitiesStats: makeAsyncInitialState({}),
  notificationPrefs: makeAsyncInitialState({}),
  deactivateTrigger: makeAsyncInitialState(),
  firmwareVersions: makeAsyncInitialState({}),
  deleteDevices: makeAsyncInitialState(),
  statistics: makeAsyncInitialState({}),
  rebootDevice: makeAsyncInitialState(),
  generatePromoCodes: makeAsyncInitialState(),
  promoCodes: makeAsyncInitialState({
    promo_codes: [],
  }),
  userGroups: makeAsyncInitialState({}),
  addUserToGroup: makeAsyncInitialState(),
  removeUserFromGroup: makeAsyncInitialState(),
  eventsByType: makeAsyncInitialState(),
  importPromoCodes: makeAsyncInitialState(),
}

export const ping = createAsyncThunk(
  "api/pingResponse",
  authRequest(config.METHODS.GET, "admin/ping")
)

export const getUsers = createAsyncThunk(
  "api/users",
  authRequest(config.METHODS.GET, "admin/users")
)

export const deleteUser = createAsyncThunk(
  "api/deleteUser",
  authRequest(config.METHODS.DELETE, "admin/user")
)

export const getHomeDetails = createAsyncThunk(
  "api/homeDetails",
  authRequest(config.METHODS.GET, "admin/home_info")
)

export const triggerOTA = createAsyncThunk(
  "api/triggerOTA",
  authRequest(config.METHODS.POST, "admin/trigger_ota")
)

export const removeUsersFromHome = createAsyncThunk(
  "api/removeUsersFromHome",
  authRequest(config.METHODS.DELETE, "admin/remove_users")
)

export const getActiveInvites = createAsyncThunk(
  "api/activeInvites",
  authRequest(config.METHODS.GET, "admin/home_active_invites")
)

export const cancelInvites = createAsyncThunk(
  "api/cancelInvites",
  authRequest(config.METHODS.DELETE, "admin/cancel_invites")
)

export const getEventStats = createAsyncThunk(
  "api/eventStats",
  authRequest(config.METHODS.GET, "events/stats")
)

export const getNotificationPrefs = createAsyncThunk(
  "api/notificationPrefs",
  authRequest(config.METHODS.GET, "admin/notification_prefs")
)

export const getActivitiesStats = createAsyncThunk(
  "api/activitiesStats",
  authRequest(config.METHODS.GET, "activities")
)

export const deactivateTrigger = createAsyncThunk(
  "api/deactivateTrigger",
  authRequest(config.METHODS.GET, "admin/home_id/trigger_id/deactivate_trigger")
)

export const getFirmwareVersions = createAsyncThunk(
  "api/firmwareVersions",
  authRequest(config.METHODS.GET, "admin/firmware_versions")
)

export const deleteDevices = createAsyncThunk(
  "api/deleteDevices",
  authRequest(config.METHODS.DELETE, "admin/devices")
)

export const getGlobalStatistics = createAsyncThunk(
  "api/globalStatistics",
  authRequest(config.METHODS.GET, "admin/global_statistics")
)

export const rebootDevice = createAsyncThunk(
  "api/rebootDevice",
  authRequest(config.METHODS.POST, "admin/device_reboot")
)

export const generatePromoCodes = createAsyncThunk(
  "api/generatePromoCodes",
  authRequest(config.METHODS.POST, "admin/generate_promo_codes")
)

export const getPromoCodes = createAsyncThunk(
  "api/getPromoCodes",
  authRequest(config.METHODS.GET, "admin/promo_codes")
)

export const getEventsByType = createAsyncThunk(
  "api/eventsByType",
  authRequest(config.METHODS.GET, "admin/home_id/device/device_id/events")
)

export const getUserGroups = createAsyncThunk(
  "api/userGroups",
  authRequest(config.METHODS.GET, "admin/user_groups/user_id")
)

export const addUserToGroup = createAsyncThunk(
  "api/addUserToGroup",
  authRequest(config.METHODS.POST, "admin/support/add_user/user_id")
)

export const removeUserFromGroup = createAsyncThunk(
  "api/removeUserFromGroup",
  authRequest(config.METHODS.DELETE, "admin/support/remove_user/user_id")
)

export const importPromoCodes = createAsyncThunk(
  "api/importPromoCodes",
  authRequest(config.METHODS.POST, "admin/promo_codes")
)

const slice = createSlice({
  name: "api",
  initialState,
  extraReducers: (builder) => {
    makeAsyncReducer(builder, ping, initialState, "pingResponse")
    makeAsyncReducer(builder, getUsers, initialState, "users")
    makeAsyncReducer(builder, deleteUser, initialState, "deleteUser")
    makeAsyncReducer(builder, getHomeDetails, initialState, "homeDetails")
    makeAsyncReducer(builder, triggerOTA, initialState, "otaResponse")
    makeAsyncReducer(builder, removeUsersFromHome, initialState, "homeDetails")
    makeAsyncReducer(builder, getActiveInvites, initialState, "activeInvites")
    makeAsyncReducer(builder, cancelInvites, initialState, "activeInvites")
    makeAsyncReducer(builder, getEventStats, initialState, "eventStats")
    makeAsyncReducer(
      builder,
      getNotificationPrefs,
      initialState,
      "notificationPrefs"
    )
    makeAsyncReducer(
      builder,
      getActivitiesStats,
      initialState,
      "activitiesStats"
    )
    makeAsyncReducer(builder, deactivateTrigger, initialState, "homeDetails")
    makeAsyncReducer(
      builder,
      getFirmwareVersions,
      initialState,
      "firmwareVersions"
    )
    makeAsyncReducer(builder, deleteDevices, initialState, "homeDetails")
    makeAsyncReducer(builder, getGlobalStatistics, initialState, "statistics")
    makeAsyncReducer(builder, rebootDevice, initialState, "rebootDevice")
    makeAsyncReducer(
      builder,
      generatePromoCodes,
      initialState,
      "generatePromoCodes"
    )
    makeAsyncReducer(builder, getPromoCodes, initialState, "promoCodes")
    makeAsyncReducer(builder, getEventsByType, initialState, "eventsByType")
    makeAsyncReducer(builder, getUserGroups, initialState, "userGroups")
    makeAsyncReducer(builder, addUserToGroup, initialState, "userGroups")
    makeAsyncReducer(builder, removeUserFromGroup, initialState, "userGroups")
    makeAsyncReducer(
      builder,
      importPromoCodes,
      initialState,
      "importPromoCodes"
    )
  },
  reducers: {
    resetPromoCodesGenerator(state) {
      return {
        ...state,
        generatePromoCodes: {
          state: null,
          error: null,
          value: null,
        },
      }
    },
    resetPromoCodesImporter(state) {
      return {
        ...state,
        importPromoCodes: {
          state: null,
          error: null,
          value: null,
        },
      }
    },
  },
})

export const { resetPromoCodesGenerator, resetPromoCodesImporter } =
  slice.actions
export default slice.reducer
