import { Box, IconButton, Typography } from "@mui/material"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import PropTypes from "prop-types"
import { blueGrey } from "@mui/material/colors"

function IconButtonWithText(props) {
  return (
    <Box
      sx={{
        backgroundColor: props.didFail
          ? "error.main"
          : props.isDisabled
          ? blueGrey[100]
          : "primary.dark",
        padding: 1,
        borderRadius: 3,
      }}
    >
      <IconButton
        sx={{
          color: "white",
        }}
        onClick={() => props.onClick()}
        disabled={props.isDisabled}
      >
        <RestartAltIcon />
      </IconButton>
      <Typography variant={"h5"} fontSize={"0.6rem"} color={"white"}>
        {props.title}
      </Typography>
    </Box>
  )
}

IconButtonWithText.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  didFail: PropTypes.bool,
}

IconButtonWithText.defaultProps = {
  title: "",
  isDisabled: false,
  didFail: false,
}

export default IconButtonWithText
