import "./index.css"
import PropTypes from "prop-types"
import React from "react"
import { Tooltip } from "@mui/material"

function Rectangle(props) {
  return (
    <Tooltip
      title={`Value for ${props.label} is ${props.weight ? props.weight : 0}`}
    >
      <div
        className={"rectangle"}
        style={{
          backgroundColor: `rgb(${
            30 + (212 - (props.weight / props.max) * 212)
          }, ${242 - (props.weight / props.max) * 242}, 242)`,
        }}
      />
    </Tooltip>
  )
}

Rectangle.propTypes = {
  label: PropTypes.string,
  weight: PropTypes.number,
  max: PropTypes.number,
}

export default Rectangle
