import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Modal,
  Table,
  TableBody,
  Typography,
} from "@mui/material"
import VerifiedIcon from "../components/VerifiedIcon"
import ClickToCopyButton from "../components/ClickToCopyButton"
import { useDispatch, useSelector } from "react-redux"
import { deleteUser, getNotificationPrefs, getUsers } from "../redux/api"
import { useEffect, useState } from "react"
import { removeSelectedUser } from "../redux/userManagement"
import { setPage } from "../redux/pages"
import KeyValueTableRow from "../components/KeyValueTableRow"
import config from "../config"
import HouseIcon from "@mui/icons-material/House"
import BackButton from "../components/BackButton"
import { setSelectedHome } from "../redux/homeManagement"
import StatefulTable from "../components/StatefulTable"
import StatefulButton from "../components/StatefulButton"
import { isAdminSelector } from "../redux/selectors"
import SupportSwitch from "../components/SupportSwitch"

const renderConfirmDeletion = (
  deleteUserResponse,
  setConfirmDeletion,
  selectedUser,
  dispatch
) => {
  return (
    <>
      <StatefulButton
        requestState={deleteUserResponse.state}
        onStartRequest={() => {
          dispatch(
            deleteUser({
              data: {
                user_id: selectedUser?.sub,
              },
            })
          ).then((response) => {
            if (!response.error) {
              setConfirmDeletion(null)
              dispatch(removeSelectedUser())
              dispatch(getUsers())
              dispatch(setPage(config.PAGES.USERS_PAGE))
            }
          })
        }}
        title="Confirm"
        color="error"
      />
      <Button onClick={() => setConfirmDeletion(null)}>Cancel</Button>
    </>
  )
}

const renderTable = (user_info) => {
  return (
    <Table>
      <TableBody>
        <KeyValueTableRow title="Full Name" value={user_info?.name} />
        <KeyValueTableRow
          title="Username"
          value={user_info?.preferred_username}
        />
        <KeyValueTableRow
          checkValue={true}
          title="E-mail"
          value={user_info?.email}
          isVerified={user_info?.email_verified === "true"}
        />
        <KeyValueTableRow
          checkValue={true}
          title="Phone Number"
          value={user_info?.phone_number}
          isVerified={user_info?.phone_number_verified === "true"}
        />
        <KeyValueTableRow
          isLastRow={true}
          title="User Created"
          value={
            user_info?.created
              ? new Date(user_info.created).toUTCString()
              : null
          }
        />
      </TableBody>
    </Table>
  )
}

const enterHome = (home_id, dispatch) => {
  dispatch(setSelectedHome(home_id))
  dispatch(setPage(config.PAGES.HOME_DETAILS_PAGE))
}

function UserDetails(props) {
  const dispatch = useDispatch()
  const selectedUsers = useSelector(
    (state) => state.userManagement.selectedUsers
  )
  const selectedUser = selectedUsers[selectedUsers.length - 1]
  const notificationPrefs = useSelector((state) => state.api.notificationPrefs)
  const preferences = notificationPrefs.value[selectedUser?.sub] ?? {}
  const deleteUserResponse = useSelector((state) => state.api.deleteUser)
  const [confirmDeletion, setConfirmDeletion] = useState(null)
  const [clickHomePosition, setClickHomePosition] = useState(null)
  const isAdmin = useSelector(isAdminSelector)

  useEffect(() => {
    dispatch(
      getNotificationPrefs({
        params: {
          user_id: selectedUser?.sub,
        },
      })
    )
  }, [dispatch, selectedUser?.sub])

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 4,
      }}
    >
      <Box>
        <BackButton
          onBack={() => {
            dispatch(removeSelectedUser())
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant={"h1"}>{selectedUser?.name}</Typography>
            <VerifiedIcon
              isVerified={selectedUser?.confirmed}
              fontVariant={"h1"}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            {selectedUser.homes.length > 0 && (
              <Box mr={2}>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  onClick={(e) => {
                    if (selectedUser.homes.length > 1) {
                      setClickHomePosition({
                        x: e.clientX - 50,
                        y: e.clientY,
                      })
                    } else {
                      enterHome(selectedUser.homes[0], dispatch)
                    }
                  }}
                >
                  User's Home
                </Button>
              </Box>
            )}
            {isAdmin &&
              (confirmDeletion === null ? (
                <Button
                  color={"error"}
                  variant={"contained"}
                  onClick={() => setConfirmDeletion(selectedUser)}
                >
                  Delete User
                </Button>
              ) : (
                renderConfirmDeletion(
                  deleteUserResponse,
                  setConfirmDeletion,
                  selectedUser,
                  dispatch
                )
              ))}
          </Box>
        </Box>
        <ClickToCopyButton copyText={selectedUser?.sub}>
          <Typography variant={"caption"}>ID#:{selectedUser?.sub}</Typography>
        </ClickToCopyButton>
        {isAdmin && <SupportSwitch userId={selectedUser?.sub} />}
      </Box>
      {renderTable(selectedUser)}
      <Box pt={6} pb={6}>
        <StatefulTable
          isRequestDone={
            notificationPrefs.state === config.REQUEST_STATE.FULFILLED
          }
          title={"Notification Preferences"}
          columns={["Source", "Notification Type"]}
          groupSubtitle="Home Identifier"
          groupedRows={Object.keys(preferences).map((home) => {
            return {
              subtitle: home,
              rows: preferences[home].map((pref) => {
                return [pref.source, pref.notification_type]
              }),
            }
          })}
        />
      </Box>
      <Modal
        open={clickHomePosition != null}
        onClick={() => setClickHomePosition(null)}
      >
        <Box
          sx={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            boxShadow: 10,
            height: "300px",
            backgroundColor: "white",
            top: clickHomePosition ? clickHomePosition.y : 0,
            left: clickHomePosition ? clickHomePosition.x : 0,
          }}
        >
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              maxHeight: "100%",
              overflow: "auto",
            }}
            component="nav"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Homes
              </ListSubheader>
            }
          >
            {selectedUser.homes.map((home) => {
              return (
                <ListItemButton
                  key={home}
                  onClick={() => {
                    enterHome(home, dispatch)
                  }}
                >
                  <ListItemIcon>
                    <HouseIcon />
                  </ListItemIcon>
                  <ListItemText primary={home} />
                </ListItemButton>
              )
            })}
          </List>
        </Box>
      </Modal>
    </Box>
  )
}

export default UserDetails
