import EmptyBattery from "@mui/icons-material/Battery0Bar"
import LowBattery from "@mui/icons-material/BatteryCharging20"
import HalfBattery from "@mui/icons-material/BatteryCharging50"
import HighBattery from "@mui/icons-material/BatteryCharging80"
import FullBattery from "@mui/icons-material/BatteryChargingFull"
import PropTypes from "prop-types"

function Battery(props) {
  const level = props.level
  if (level < 0.1) {
    return <EmptyBattery />
  } else if (level < 0.35) {
    return <LowBattery />
  } else if (level < 0.65) {
    return <HalfBattery />
  } else if (level < 0.9) {
    return <HighBattery />
  } else {
    return <FullBattery />
  }
}

Battery.propTypes = {
  level: PropTypes.number,
}

export default Battery
