import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux"
import { getEventStats } from "../../redux/api"
import config from "../../config"
import CenteredTableRow from "../CenteredTableRow"
import GraphContainer from "../GraphContainer"
import PropTypes from "prop-types"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import DateUtils from "../../utils/dateUtils"

function LineChart(props) {
  const getValueByAggregationType = (eventData) => {
    switch (aggregationType) {
      case "sum":
        return eventData.sum_event_value ? eventData.sum_event_value : 0
      case "cnt":
        return eventData.num_events ? eventData.num_events : 0
      case "min":
        return eventData.min_event_value ? eventData.min_event_value : 0
      case "max":
        return eventData.max_event_value ? eventData.max_event_value : 0
      default:
        return eventData.avg_event_value ? eventData.avg_event_value : 0
    }
  }

  const getChartNameByCode = (code) => {
    switch (code) {
      case config.EVENT_CODE.TAG_TEMPERATURE:
        return "Temperature"
      case config.EVENT_CODE.LIGHT:
        return "Light"
      case config.EVENT_CODE.HUB_HUMIDITY:
      case config.EVENT_CODE.SAT_HUMIDITY:
        return "Humidity"
      default:
        return "Chart"
    }
  }

  const onTabChanged = (_, value) => {
    setCurrentTab(value)
  }

  const dispatch = useDispatch()
  const [aggregationType, setAggregationType] = useState("max")
  const eventStatsResponse = useSelector((state) => state.api.eventStats)
  const [currentTab, setCurrentTab] = useState(props.codes[0])
  const selectedDevice = useSelector(
    (state) => state.homeManagement.selectedDevice
  )
  const responseData = eventStatsResponse.value[selectedDevice.device_id]

  const charts = props.codes.reduce((acc, code) => {
    return {
      ...acc,
      [code]: [
        {
          data: responseData?.values[code]
            ? responseData.values[code].map((eventData) => {
                return [
                  new Date(eventData.date_chunk).getTime(),
                  getValueByAggregationType(eventData),
                ]
              })
            : [],
        },
      ],
    }
  }, {})

  const options = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    title: {
      text: selectedDevice.timezone,
      style: {
        fontSize: "16",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value ? value.toFixed(2) : value
        },
      },
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        datetimeUTC: false,
        formatter: function (value) {
          return new Date(value).toLocaleDateString("en-US", {
            timeZone: selectedDevice.timezone,
          })
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy: hh:mm",
        formatter: function (value) {
          return new Date(value).toLocaleString("en-US", {
            timeZone: selectedDevice.timezone,
          })
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  }

  return (
    <Box pt={15}>
      <Typography variant={"h6"}>Event Stats</Typography>
      <GraphContainer
        onAggregationTypeChanged={(value) => setAggregationType(value)}
        aggregationType={aggregationType}
        onSendRequest={(start, end) => {
          dispatch(
            getEventStats({
              params: {
                home_id: selectedDevice.home_id,
                start: DateUtils.overrideTimezoneInfo(
                  start,
                  selectedDevice.timezone
                ).toISOString(),
                end: DateUtils.overrideTimezoneInfo(
                  end,
                  selectedDevice.timezone
                ).toISOString(),
                chunk_size: config.GRANULARITY.QUARTER,
                device_id: selectedDevice.device_id,
              },
              id: selectedDevice.device_id,
            })
          )
        }}
        hideGranularity={true}
        isLoading={
          eventStatsResponse.state === config.REQUEST_STATE.PENDING &&
          eventStatsResponse.id === selectedDevice.device_id
        }
        isEmpty={
          props.codes.filter((code) => charts[code][0].data.length > 0)
            .length === 0
        }
      >
        <CenteredTableRow>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={onTabChanged}
              aria-label="basic tabs example"
            >
              {props.codes.map((code) => (
                <Tab key={code} value={code} label={getChartNameByCode(code)} />
              ))}
            </Tabs>
          </Box>
        </CenteredTableRow>
        {props.codes.map((code) => {
          return currentTab === code && charts[code].length > 0 ? (
            <CenteredTableRow key={code}>
              <ReactApexChart
                options={options}
                series={charts[code]}
                type="area"
                height={350}
              />
            </CenteredTableRow>
          ) : null
        })}
      </GraphContainer>
    </Box>
  )
}

LineChart.propTypes = {
  codes: PropTypes.array,
}

export default LineChart
