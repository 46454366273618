import { useDispatch, useSelector } from "react-redux"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { getGlobalStatistics, ping } from "../redux/api"
import ClickToCopyButton from "../components/ClickToCopyButton"
import config from "../config"
import ErrorBoundary from "../components/ErrorBoundary"
import RefreshIcon from "@mui/icons-material/Refresh"
import StatefulWrapper from "../components/StatefulWrapper"
import React from "react"

function pingStatus(pingResponse) {
  if (pingResponse.state === null) {
    return null
  } else if (pingResponse.error) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Error: {pingResponse.error.message}
      </Typography>
    )
  } else if (pingResponse.state === config.REQUEST_STATE.PENDING) {
    return <Typography>Loading...</Typography>
  } else if (pingResponse.state === config.REQUEST_STATE.FULFILLED) {
    return (
      <Typography sx={{ color: "success.main" }}>
        System check: Success!
      </Typography>
    )
  }
}

function Dashboard(props) {
  const user = useSelector((state) => state.user)
  const pingResponse = useSelector((state) => state.api.pingResponse)
  const globalStats = useSelector((state) => state.api.statistics)
  const dispatch = useDispatch()
  const defaultText = "Coming Soon..."
  const stats = globalStats.value

  const renderCards = (valuesDict) => {
    return Object.entries(valuesDict).map(([key, val]) => {
      return (
        <Box display={"inline-block"} padding={"1rem"} key={key}>
          <Card>
            <CardHeader
              title={key}
              titleTypographyProps={{ variant: "h4" }}
              sx={{
                backgroundColor: "rgba(49, 62, 141, 1)",
                color: "white",
                fontSize: "0.5rem",
              }}
            />
            <CardContent>{renderCardContent(val)}</CardContent>
          </Card>
        </Box>
      )
    })
  }

  const renderCardContent = (valuesDict) => {
    return (
      <Stack direction={"column"} spacing={3}>
        {Object.entries(valuesDict).map(([key, val]) => {
          return (
            <Container key={key}>
              <Stack direction={"row"} spacing={1}>
                <Typography fontWeight={"bold"} fontSize={"0.85rem"}>
                  {key} :
                </Typography>
                <Typography fontSize={"0.85rem"}>
                  {val ? val : defaultText}
                </Typography>
              </Stack>
            </Container>
          )
        })}
      </Stack>
    )
  }
  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Stack>
            <Typography align={"left"} variant={"h1"}>
              Welcome, {user.attributes.name}!
            </Typography>
            <ClickToCopyButton copyText={user.username}>
              <Typography variant={"body1"}>ID#:{user.username}</Typography>
            </ClickToCopyButton>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography align={"left"} variant={"h1"}>
            Check Server Status
          </Typography>
          <Typography align={"left"} variant={"body1"}>
            Instant test of Nomo's {process.env.REACT_APP_ENV} backend
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}
          >
            <Button onClick={() => dispatch(ping())}>Run</Button>
            <ErrorBoundary
              hideError={pingResponse.state === config.REQUEST_STATE.PENDING}
            >
              {pingStatus(pingResponse)}
            </ErrorBoundary>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography align={"left"} variant={"h1"}>
              Statistics
            </Typography>
            <IconButton
              onClick={() => {
                dispatch(getGlobalStatistics())
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>

          <StatefulWrapper requestState={globalStats.state}>
            {
              <div
                style={{
                  flexWrap: "wrap",
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "20px",
                }}
              >
                {renderCards({
                  Alerts: {
                    Active: stats.alerts?.active,
                    "Average Active": (
                      stats.alerts?.active / stats.homes?.cnt
                    ).toFixed(2),
                    Total: stats.alerts?.total_cnt,
                    "Average Total": (
                      stats.alerts?.total_cnt / stats.homes?.total_cnt
                    ).toFixed(2),
                  },
                  AWS: {
                    "Cognito Entries": stats.users?.cnt,
                    "SIP Instances": defaultText,
                  },
                  Devices: {
                    Total: stats.devices?.total_cnt,
                    Current: stats.devices?.cnt,
                  },
                  Homes: {
                    Total: stats.homes?.total_cnt,
                    Current: stats.homes?.cnt,
                  },
                  Messages: {
                    Total: stats.events?.total_cnt,
                    "Average per Home": (
                      stats.events?.total_cnt / stats.homes?.total_cnt
                    ).toFixed(2),
                  },
                  "New Connections": {
                    Today: defaultText,
                    "This Month": defaultText,
                  },
                  "SIP Minutes": {
                    Total: defaultText,
                    "Average per Home": defaultText,
                  },
                  "911 Calls": {
                    Total: defaultText,
                    "Average per Home": defaultText,
                  },
                })}
              </div>
            }
          </StatefulWrapper>
        </CardContent>
      </Card>
    </Stack>
  )
}

Dashboard.propTypes = {}

export default Dashboard
