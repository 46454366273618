import { Button, CircularProgress } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import CommonModal from "../CommonModal"
import config from "../../config"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { isAdminSelector } from "../../redux/selectors"

function ButtonWithModal(props) {
  const isAdmin = useSelector(isAdminSelector)
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          size={"large"}
          sx={{
            justifyContent: "flex-start",
            backgroundColor: "rgba(49, 62, 141, 1)",
          }}
          onClick={() => setShowModal(true)}
        >
          {props.title}
        </Button>
      )}
      <CommonModal show={showModal} onClose={() => setShowModal(false)}>
        {props.responseState === config.REQUEST_STATE.PENDING ? (
          <CircularProgress size={50} />
        ) : (
          props.children
        )}
      </CommonModal>
    </>
  )
}

export default ButtonWithModal
