import { useDispatch, useSelector } from "react-redux"
import { getUsers } from "../redux/api"
import _ from "lodash"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { Button, LinearProgress, Paper } from "@mui/material"
import { setPage } from "../redux/pages"
import { setSelectedUser } from "../redux/userManagement"
import config from "../config"

function Users(props) {
  const dispatch = useDispatch()
  const userResponse = useSelector((state) => state.api.users)
  const headers = [
    {
      field: "sub",
      headerName: "ID",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "preferred_username",
      headerName: "Preferred Username",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "homes",
      headerName: "Homes",
      flex: 1,
      minWidth: 300,
    },
  ]
  return (
    <Paper className="App">
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          columnVisibilityModel={{
            homes: false,
          }}
          rows={userResponse.value.users.map((user) => ({
            id: user.Username,
            ..._.fromPairs(
              user.Attributes.map(({ Name, Value }) => [Name, Value])
            ),
            created: user.UserCreateDate,
            last_modified: user.UserLastModifiedDate,
            confirmed: user.UserStatus === "CONFIRMED",
            homes: user.homes,
          }))}
          columns={headers}
          onCellClick={(event) => {
            dispatch(setSelectedUser(event.row))
            dispatch(setPage(config.PAGES.USER_DETAILS_PAGE))
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: LinearProgress,
          }}
          loading={userResponse.state === config.REQUEST_STATE.PENDING}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
          }}
        />
      </div>
      <Button
        disabled={userResponse.state === config.REQUEST_STATE.PENDING}
        onClick={() => dispatch(getUsers())}
      >
        Refresh Users
      </Button>
    </Paper>
  )
}

Users.propTypes = {}

export default Users
