import { createSlice } from "@reduxjs/toolkit"

const initialState = null

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      if (action.payload.username) {
        return action.payload
      }
    },
  },
})

export const { setUser } = slice.actions
export default slice.reducer
