import "./index.css"
import ReactApexChart from "react-apexcharts"
import { getActivitiesStats } from "../../redux/api"
import CenteredTableRow from "../CenteredTableRow"
import GraphContainer from "../GraphContainer"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import config from "../../config"
import _ from "lodash"
import StatefulTable from "../StatefulTable"
import { Box } from "@mui/material"
import DateUtils from "../../utils/dateUtils"

function TimelineChart(props) {
  const dispatch = useDispatch()
  const activitiesResponse = useSelector((state) => state.api.activitiesStats)
  const activitiesData = activitiesResponse.value[props.home_id]
  const [currentChunk, setCurrentChunk] = useState(null)
  const spread = activitiesData?.flatMap((a) => [
    // separate rows so each activity type has an element
    {
      home_id: a.home_id,
      quarter_hour: a.quarter_hour,
      adl_type: "did_eat",
      adl_value: a.did_eat,
      adl_score: a.eating_score,
      event_count: a.total_events,
    },
    {
      home_id: a.home_id,
      quarter_hour: a.quarter_hour,
      adl_type: "did_sleep",
      adl_value: a.did_sleep,
      adl_score: a.sleep_score,
      event_count: a.total_events,
    },
    {
      home_id: a.home_id,
      quarter_hour: a.quarter_hour,
      adl_type: "is_mobile",
      adl_value: a.is_mobile,
      adl_score: a.mobility_score,
      event_count: a.total_events,
    },
    {
      home_id: a.home_id,
      quarter_hour: a.quarter_hour,
      adl_type: "is_regular",
      adl_value: a.is_regular,
      adl_score: a.regularity_score,
      event_count: a.total_events,
    },
  ])

  const getAggregatedActivities = () => {
    if (!activitiesData) {
      return []
    }
    const aggregated = _.chain(spread)
      .filter((a) => a.adl_value)
      .groupBy("adl_type")
      .mapValues((events) => {
        const sorted = events.sort((a) => new Date(a.quarter_hour).getTime())
        const grouped = sorted.reduce((acc, v, i, s) => {
          if (i === 0) {
            return [[v]]
          }
          const prev_adl = s[i - 1]
          const prev_quarter_hour = new Date(
            new Date(v.quarter_hour).getTime() - 15 * config.TIME_IN_MS.MINUTE
          )
          if (
            new Date(prev_adl.quarter_hour).getTime() ===
              new Date(prev_quarter_hour).getTime() &&
            prev_adl.adl_value === v.adl_value
          ) {
            return [...acc.slice(0, acc.length - 1), [...acc.pop(), v]]
          }
          return [...acc, [v]]
        }, [])
        const summarized = grouped.map((g) => {
          let end = new Date(_.maxBy(g, "quarter_hour").quarter_hour)
          end.setUTCMinutes(end.getUTCMinutes() + 14, 59, 999)
          return {
            home_id: g[0].home_id,
            start: _.minBy(g, "quarter_hour").quarter_hour,
            end,
            adl_type: g[0].adl_type,
            adl_value: g[0].adl_value,
            avg_score: g.reduce((acc, v) => acc + v.adl_score, 0) / g.length,
            event_count: g.reduce((acc, v) => acc + v.event_count, 0),
          }
        })
        return summarized
      })
      .value()
    return aggregated
  }

  const aggregatedActivitiesData = getAggregatedActivities()

  const getData = (prop) => {
    if (!aggregatedActivitiesData[prop]) {
      return []
    }
    return aggregatedActivitiesData[prop].map((activity) => {
      return {
        x: "ADL",
        y: [
          new Date(activity.start).getTime(),
          new Date(activity.end).getTime(),
        ],
      }
    })
  }

  const seriesData = [
    {
      prop: "did_eat",
      name: "Eating",
      data: getData("did_eat"),
    },
    {
      prop: "did_sleep",
      name: "Sleeping",
      data: getData("did_sleep"),
    },
    {
      prop: "is_mobile",
      name: "Motion",
      data: getData("is_mobile"),
    },
    {
      prop: "is_regular",
      name: "Regular",
      data: getData("is_regular"),
    },
  ]

  let isEmpty = true
  seriesData.forEach((series) => {
    if (series.data.length > 0) {
      isEmpty = false
    }
  })

  const tableProps = {
    series: seriesData,
    options: {
      title: {
        text: props.timezone,
        style: {
          fontSize: "16",
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
        },
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            setTimeout(
              () =>
                setCurrentChunk({
                  seriesIndex: config.seriesIndex,
                  startDate:
                    seriesData[config.seriesIndex].data[config.dataPointIndex]
                      .y[0],
                  endDate:
                    seriesData[config.seriesIndex].data[config.dataPointIndex]
                      .y[1],
                }),
              10
            )
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          formatter: function (value) {
            return new Date(value).toLocaleDateString("en-US", {
              timeZone: props.timezone,
            })
          },
        },
      },
      stroke: {
        width: 1,
      },
      fill: {
        type: "solid",
        opacity: 0.6,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value
          },
        },
        x: {
          formatter: function (value) {
            return new Date(value).toLocaleString("en-US", {
              timeZone: props.timezone,
            })
          },
        },
      },
    },
  }

  return (
    <Box>
      <GraphContainer
        onSendRequest={(start, end) => {
          setCurrentChunk(null)
          dispatch(
            getActivitiesStats({
              params: {
                start: DateUtils.overrideTimezoneInfo(
                  start,
                  props.timezone
                ).toISOString(),
                end: DateUtils.overrideTimezoneInfo(
                  end,
                  props.timezone
                ).toISOString(),
                home_id: props.home_id,
              },
              id: props.home_id,
            })
          )
        }}
        useUTC={false}
        hideGranularity={true}
        isLoading={
          activitiesResponse.state === config.REQUEST_STATE.PENDING &&
          activitiesResponse.id === props.home_id
        }
        isEmpty={isEmpty}
      >
        <CenteredTableRow>
          <ReactApexChart
            options={tableProps.options}
            series={tableProps.series}
            type="rangeBar"
            height={250}
          />
        </CenteredTableRow>
      </GraphContainer>
      {currentChunk &&
        activitiesResponse.state === config.REQUEST_STATE.FULFILLED && (
          <div className={"table-container"}>
            <StatefulTable
              title={seriesData[currentChunk.seriesIndex].name}
              columns={["Timestamp", "Score", "Events count"]}
              rows={spread
                .filter((element) => {
                  return (
                    element.adl_value &&
                    element.adl_type ===
                      seriesData[currentChunk.seriesIndex].prop &&
                    new Date(element.quarter_hour).getTime() >=
                      currentChunk.startDate &&
                    new Date(element.quarter_hour).getTime() <=
                      currentChunk.endDate
                  )
                })
                .map((activity) => [
                  new Date(activity.quarter_hour).toLocaleString("en-US", {
                    timeZone: props.timezone,
                  }),
                  activity.adl_score,
                  activity.event_count,
                ])}
            />
          </div>
        )}
    </Box>
  )
}

TimelineChart.propTypes = {
  home_id: PropTypes.string,
  timezone: PropTypes.string,
}

export default TimelineChart
