import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import {store} from './store'
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from './theme'

const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.classList.add("background-nomo")
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline/>
    <Provider store={store}>
      <App/>
    </Provider>
  </ThemeProvider>
);
