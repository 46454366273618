import {createTheme} from "@mui/material";
import PoppinsLightTTF from './font/Poppins/Poppins-Light.ttf'
import PoppinsSemiBoldTTF from './font/Poppins/Poppins-SemiBold.ttf'

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Poppins-SemiBold',
      fontSize: '2.6rem'
    },
    h2: {
      fontFamily: 'Poppins-SemiBold',
      fontSize: '1.6rem'
    },
    h3: {
      fontFamily: 'Poppins-SemiBold',
      fontSize: '1.1rem'
    },
    h4: {
      fontFamily: 'Poppins-SemiBold',
      fontSize: '1rem'
    },
    h5: {
      fontFamily: 'Poppins-SemiBold'
    },
    h6: {
      fontFamily: 'Poppins-SemiBold'
    },
    button: {
      fontFamily: 'Poppins-SemiBold'
    },
    body1: {
      fontFamily: 'Poppins-Light'
    },
    body2: {
      fontFamily: 'Poppins-Light',
      fontSize: '1rem'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins-Light';
          src: local('Poppins-Light'), local('Poppins-Light'), url(${PoppinsLightTTF}) format('truetype');
        }
        
        @font-face {
          font-family: 'Poppins-SemiBold';
          src: local('Poppins-SemiBold'), local('Poppins-SemiBold'), url(${PoppinsSemiBoldTTF}) format('truetype');
        }
      `
    },
  },
})

export default theme