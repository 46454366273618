import React from "react"
import { Box, IconButton, Menu } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import PropTypes from "prop-types"

function NavBarMenu(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <Box
      sx={{ display: props.showOnlyXS ? { xs: "flex", md: "none" } : "flex" }}
    >
      <IconButton
        size="large"
        aria-label={props.ariaLabel}
        aria-controls={props.id}
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        {props.icon}
      </IconButton>
      <Menu
        id={props.id}
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: props.hAlign,
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: props.hAlign,
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        onClick={handleCloseNavMenu}
        sx={{
          display: props.showOnlyXS ? { xs: "block", md: "none" } : "block",
        }}
      >
        {props.children}
      </Menu>
    </Box>
  )
}

NavBarMenu.propTypes = {
  icon: PropTypes.element,
  showOnlyXS: PropTypes.bool,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  hAlign: PropTypes.string,
}

NavBarMenu.defaultProps = {
  icon: <MenuIcon />,
  showOnlyXS: true,
  hAlign: "left",
}

export default NavBarMenu
