import { useDispatch, useSelector } from "react-redux"
import { Box, Button, LinearProgress, Paper, Stack } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import config from "../config"
import { getPromoCodes } from "../redux/api"
import util from "../utils/util"
import ButtonWithModal from "../components/ButtonWithModal"
import PromoCodesForm from "../components/PromoCodesForm"
import ImportPromoCodesForm from "../components/ImportPromoCodesForm"

function PromoCodes(props) {
  const dispatch = useDispatch()
  const promoCodesResponse = useSelector((state) => state.api.promoCodes)
  const generateResponse = useSelector((state) => state.api.generatePromoCodes)

  const headers = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "promo_code",
      headerName: "Promo Code",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "duration_days",
      headerName: "Days",
      flex: 1,
      minWidth: 60,
    },
    {
      field: "tier",
      headerName: "Tier",
      flex: 1,
      minWidth: 260,
    },
    {
      field: "home_id",
      headerName: "Home Identifier",
      flex: 1,
      minWidth: 340,
    },
    {
      field: "redeemed_at",
      headerName: "Redeemed at",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "code_type",
      headerName: "Code Type",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "created_for",
      headerName: "Created for",
      flex: 1,
      minWidth: 160,
    },
    {
      field: "creation_requested_by",
      headerName: "Requested by",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "created_at",
      headerName: "Created at",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "created_by",
      headerName: "Created by",
      flex: 1,
      minWidth: 380,
    },
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "creation_reason",
      headerName: "Reason",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "creation_notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "shared_credits_remaining",
      headerName: "Shared Remaining",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "shared_credits_total",
      headerName: "Shared Total",
      flex: 1,
      minWidth: 120,
    },
  ]
  return (
    <Paper className="App">
      <Box p={2}>
        <Stack
          spacing={4}
          direction="row"
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <ButtonWithModal
            responseState={generateResponse.state}
            title={"Generate Promo Codes"}
          >
            <PromoCodesForm />
          </ButtonWithModal>
          <ButtonWithModal title={"Import Promo Codes"}>
            <ImportPromoCodesForm />
          </ButtonWithModal>
        </Stack>
      </Box>
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          rows={
            promoCodesResponse.value.promo_codes?.map((promo_code, index) => ({
              id: index + 1,
              ...promo_code,
              created_at: promo_code.created_at
                ? new Date(promo_code.created_at).toLocaleString()
                : "",
              redeemed_at: promo_code.redeemed_at
                ? new Date(promo_code.redeemed_at).toLocaleString()
                : "",
            })) ?? []
          }
          columns={headers}
          onCellClick={(event) => util.copyToClipboard(event.row.promo_code)}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: LinearProgress,
          }}
          loading={promoCodesResponse.state === config.REQUEST_STATE.PENDING}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: { fileName: "promo_codes" },
              printOptions: { fileName: "promo_codes" },
            },
          }}
        />
      </div>
      <Button
        disabled={promoCodesResponse.state === config.REQUEST_STATE.PENDING}
        onClick={() => dispatch(getPromoCodes())}
      >
        Refresh Promo Codes
      </Button>
    </Paper>
  )
}

export default PromoCodes
