import { createSlice } from "@reduxjs/toolkit"

const initialState = { selectedUsers: [] }

const slice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setSelectedUser(state, action) {
      return { selectedUsers: [...state.selectedUsers, action.payload] }
    },
    removeSelectedUser(state) {
      return {
        selectedUsers: state.selectedUsers.filter(
          (_, i) => i !== state.selectedUsers.length - 1
        ),
      }
    },
  },
})

export const { setSelectedUser, removeSelectedUser } = slice.actions
export default slice.reducer
