import "./index.css"
import PropTypes from "prop-types"
import MUIVerifiedIcon from "@mui/icons-material/Verified"

function VerifiedIcon(props) {
  let size = undefined
  if (/h[1-3]/.test(props.fontVariant)) size = "large"
  if (/h[4-6]/.test(props.fontVariant)) size = "medium"
  if (props.isVerified) {
    return <MUIVerifiedIcon fontSize={size} className={"verified-icon"} />
  } else {
    return <MUIVerifiedIcon fontSize={size} className={"not-verified-icon"} />
  }
}

VerifiedIcon.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  fontVariant: PropTypes.string,
}

export default VerifiedIcon
