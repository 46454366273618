import React, { useEffect } from "react"
import "./App.css"
import { Amplify } from "aws-amplify"
import { withAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import awsExports from "./aws-exports"
import NavBar from "./components/NavBar"
import { setUser } from "./redux/user"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import pages from "./pages"
import logo from "./img/Nomo_Logo_White.png"
import { Paper, Typography } from "@mui/material"
import {
  getFirmwareVersions,
  getGlobalStatistics,
  getPromoCodes,
  getUsers,
} from "./redux/api"

Amplify.configure(awsExports)

function App({ signOut, user }) {
  const userState = useSelector((state) => state.user)
  const pagesState = useSelector((state) => state.pages)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUsers())
    dispatch(getFirmwareVersions())
    dispatch(getGlobalStatistics())
    dispatch(getPromoCodes())
  }, [dispatch])
  if (user && !userState) {
    dispatch(
      setUser({
        ..._.pick(user, ["attributes", "userDataKey", "username"]),
        idToken: user.signInUserSession.idToken.jwtToken,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        refreshToken: user.signInUserSession.refreshToken.token,
        groups: user.signInUserSession.idToken.payload["cognito:groups"],
      })
    )
  }
  const Page = pages[pagesState.currentPage].pageName
  return (
    <div className={"root"}>
      <NavBar signOut={signOut} />
      <Paper className="App">
        <div className="background-container">
          <Page />
        </div>
      </Paper>
    </div>
  )
}

export default withAuthenticator(App, {
  hideSignUp: true,
  loginMechanisms: ["username"],
  components: {
    Header() {
      return (
        <span>
          <img src={logo} className={"header-logo"} alt={"Nomo logo"} />
          <Typography variant={"h5"} className={"sign-in-header"}>
            Admin Panel
          </Typography>
        </span>
      )
    },
  },
})
