import "./index.css"
import React from "react"
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material"
import PropTypes from "prop-types"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { useDispatch, useSelector } from "react-redux"
import pages from "../../pages"
import { setPage } from "../../redux/pages"
import logo from "../../img/Nomo_Logo_White.png"
import RefreshIcon from "@mui/icons-material/Refresh"
import { getFirmwareVersions } from "../../redux/api"
import NavBarMenu from "../NavBarMenu"
import { isAdminSelector } from "../../redux/selectors"

function NavBar(props) {
  const pagesState = useSelector((state) => state.pages)
  const dispatch = useDispatch()
  const isAdmin = useSelector(isAdminSelector)

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar position={"fixed"} className={"app-bar"}>
        <Container maxWidth={"xl"}>
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <img
                src={logo}
                className={"header-logo"}
                alt={"Nomo header logo"}
              />
            </Box>
            <NavBarMenu id="menu-appbar" ariaLabel="account of current user">
              {Object.keys(pages)
                .filter((page) => pages[page].navBarDisplayName)
                .map((page) => (
                  <MenuItem
                    key={page}
                    disabled={pagesState.currentPage === page}
                    onClick={() => {
                      dispatch(setPage(page))
                    }}
                  >
                    <Typography textAlign="center" variant={"button"}>
                      {pages[page].navBarDisplayName}
                    </Typography>
                  </MenuItem>
                ))}
            </NavBarMenu>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <img
                src={logo}
                className={"header-logo-small"}
                alt={"Nomo header logo"}
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {Object.keys(pages)
                .filter((page) => pages[page].navBarDisplayName)
                .map((page) => (
                  <Button
                    key={page}
                    onClick={() => {
                      dispatch(setPage(page))
                    }}
                    sx={{
                      my: 2,
                      mx: 1,
                      color: "white",
                      display: "block",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    {pages[page].navBarDisplayName}
                  </Button>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
              }}
            >
              {isAdmin && (
                <NavBarMenu
                  id="menu-refresh"
                  icon={<RefreshIcon />}
                  hAlign="right"
                  showOnlyXS={false}
                >
                  <MenuItem
                    onClick={() => {
                      dispatch(getFirmwareVersions())
                    }}
                  >
                    <Typography textAlign="center" variant={"button"}>
                      REFRESH FIRMWARE VERSIONS
                    </Typography>
                  </MenuItem>
                </NavBarMenu>
              )}
              <IconButton color="inherit" onClick={props.signOut}>
                <ExitToAppIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}

NavBar.propTypes = {
  signOut: PropTypes.func,
}

export default NavBar
