import Dashboard from "./Dashboard"
import Users from "./Users"
import UserDetails from "./UserDetails"
import HomeDetails from "./HomeDetails"
import config from "../config"
import HomeDevice from "./HomeDevice"
import PromoCodes from "./promoCodes"

const pages = {
  [config.PAGES.DASHBOARD_PAGE]: {
    pageName: Dashboard,
    navBarDisplayName: "DASHBOARD",
  },
  [config.PAGES.USERS_PAGE]: {
    pageName: Users,
    navBarDisplayName: "USERS",
  },
  [config.PAGES.PROMO_CODES_PAGE]: {
    pageName: PromoCodes,
    navBarDisplayName: "PROMO CODES",
  },
  [config.PAGES.USER_DETAILS_PAGE]: {
    pageName: UserDetails,
  },
  [config.PAGES.HOME_DETAILS_PAGE]: {
    pageName: HomeDetails,
  },
  [config.PAGES.HOME_DEVICE_PAGE]: {
    pageName: HomeDevice,
  },
}

export default pages
