import { IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import config from "../../config"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import HelpIcon from "@mui/icons-material/Help"
import {
  addUserToGroup,
  getUserGroups,
  removeUserFromGroup,
} from "../../redux/api"
import { useEffect } from "react"
import PropTypes from "prop-types"

function SupportSwitch(props) {
  const dispatch = useDispatch()

  const userGroupsResponse = useSelector((state) => state.api.userGroups)
  const userGroups = userGroupsResponse.value[props.userId]
  const noInfo = !userGroups
  const supportIsIncluded = userGroups?.find(
    (userGroup) => userGroup.GroupName === config.USER_GROUPS.SUPPORT
  )

  useEffect(() => {
    dispatch(
      getUserGroups({
        pathVariables: {
          user_id: props.userId,
        },
      })
    )
  }, [dispatch, props.userId])

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography
        align={"center"}
        variant={"h4"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        Support
      </Typography>
      {
        <IconButton
          onClick={() => {
            supportIsIncluded
              ? dispatch(
                  removeUserFromGroup({
                    pathVariables: {
                      user_id: props.userId,
                    },
                  })
                )
              : dispatch(
                  addUserToGroup({
                    pathVariables: {
                      user_id: props.userId,
                    },
                  })
                )
          }}
          disabled={noInfo}
          color={!noInfo && supportIsIncluded ? "error" : "success"}
        >
          {noInfo ? (
            <HelpIcon />
          ) : (
            <Tooltip
              title={
                supportIsIncluded ? "Remove from Support" : "Add to support"
              }
            >
              {supportIsIncluded ? <RemoveCircleIcon /> : <AddCircleIcon />}
            </Tooltip>
          )}
        </IconButton>
      }
    </Stack>
  )
}

SupportSwitch.propTypes = {
  userId: PropTypes.string,
}

export default SupportSwitch
