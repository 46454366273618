import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import {
  DateTimePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import config from "../../config"
import SendIcon from "@mui/icons-material/Send"
import CenteredTableRow from "../CenteredTableRow"
import PropTypes from "prop-types"
import { useState } from "react"

function GraphContainer(props) {
  const [currentGranularity, setCurrentGranularity] = useState(
    config.GRANULARITY.DAY
  )
  const [currentStartDate, setCurrentStartDate] = useState(null)
  const [currentEndDate, setCurrentEndDate] = useState(null)

  const handleStartDateChange = (value) => {
    setCurrentStartDate(value["$d"])
  }

  const handleEndDateChange = (value) => {
    setCurrentEndDate(value["$d"])
  }

  const updateGranularity = (event) => {
    const granularity = event.target.value
    if (currentEndDate !== null) {
      const previousEndDate = new Date(currentEndDate)
      if (granularity === config.GRANULARITY.HOUR) {
        previousEndDate.setHours(23, 0)
      } else if (granularity === config.GRANULARITY.QUARTER) {
        previousEndDate.setHours(23, 45)
      } else {
        previousEndDate.setHours(0, 0)
      }
      setCurrentEndDate(previousEndDate)
    }
    setCurrentGranularity(granularity)
  }

  const getRequestDate = (date) => {
    switch (currentGranularity) {
      case config.GRANULARITY.WEEK:
      case config.GRANULARITY.DAY:
        let utcDate = new Date(date)
        if (props.useUTC) {
          utcDate.setUTCHours(24, 0)
        }
        return utcDate
      default:
        return date
    }
  }

  return (
    <Box p={2}>
      <Box display="flex" p={2} justifyContent={"space-between"}>
        {props.shouldScroll !== undefined && (
          <Tooltip title="Expand">
            <IconButton
              onClick={() => props.onScrollPressed()}
              color={props.shouldScroll ? "primary" : "default"}
            >
              <OpenInFullIcon />
            </IconButton>
          </Tooltip>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {currentGranularity === config.GRANULARITY.QUARTER ||
          currentGranularity === config.GRANULARITY.HOUR ? (
            <Box>
              <DateTimePicker
                minutesStep={
                  currentGranularity === config.GRANULARITY.QUARTER ? 15 : 60
                }
                label="Start Date and Time"
                value={currentStartDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
              <DateTimePicker
                minutesStep={
                  currentGranularity === config.GRANULARITY.QUARTER ? 15 : 60
                }
                label="End Date and Time"
                value={currentEndDate}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          ) : (
            <Box>
              <DesktopDatePicker
                label="Start Date"
                value={currentStartDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
              <DesktopDatePicker
                label="End Date"
                value={currentEndDate}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          )}
        </LocalizationProvider>

        {!props.hideGranularity && (
          <FormControl style={{ width: 135 }}>
            <InputLabel id="granularity">Granularity</InputLabel>
            <Select
              labelId="granularity"
              value={currentGranularity}
              label="Granularity"
              onChange={updateGranularity}
            >
              <MenuItem value={config.GRANULARITY.DAY}>Day</MenuItem>
              <MenuItem value={config.GRANULARITY.HOUR}>Hour</MenuItem>
              <MenuItem value={config.GRANULARITY.QUARTER}>15-Minute</MenuItem>
              <MenuItem value={config.GRANULARITY.WEEK}>Week</MenuItem>
            </Select>
          </FormControl>
        )}

        {props.aggregationType.length > 0 && (
          <FormControl style={{ width: 135 }}>
            <InputLabel id="Method">Method</InputLabel>
            <Select
              labelId="method"
              value={props.aggregationType}
              label="Method"
              onChange={(value) =>
                props.onAggregationTypeChanged(value.target.value)
              }
            >
              <MenuItem value="avg">Average</MenuItem>
              <MenuItem value="sum">Sum</MenuItem>
              <MenuItem value="cnt">Count</MenuItem>
              <MenuItem value="min">Min</MenuItem>
              <MenuItem value="max">Max</MenuItem>
            </Select>
          </FormControl>
        )}

        <IconButton
          onClick={() =>
            props.onSendRequest(
              getRequestDate(currentStartDate),
              getRequestDate(currentEndDate),
              currentGranularity
            )
          }
          disabled={currentEndDate <= currentStartDate}
          color="primary"
        >
          <SendIcon />
        </IconButton>
      </Box>

      <div
        style={{
          overflowX: "auto",
        }}
      >
        <Table>
          <TableBody>
            {props.isLoading ? (
              <CenteredTableRow>
                <CircularProgress />
              </CenteredTableRow>
            ) : props.isEmpty ? (
              <CenteredTableRow>
                <Typography color={"gray"}>
                  No data. Please select parameters and start a new request.
                </Typography>
              </CenteredTableRow>
            ) : (
              props.children
            )}
          </TableBody>
        </Table>
      </div>
    </Box>
  )
}

GraphContainer.propTypes = {
  shouldScroll: PropTypes.bool,
  onSendRequest: PropTypes.func,
  onAggregationTypeChanged: PropTypes.func,
  aggregationType: PropTypes.string,
  hideGranularity: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  useUTC: PropTypes.bool,
  onScrollPressed: PropTypes.func,
}

GraphContainer.defaultProps = {
  aggregationType: "",
  useUTC: true,
}

export default GraphContainer
