import {configureStore} from "@reduxjs/toolkit";
import UserReducer from './redux/user'
import PagesReducer from './redux/pages'
import APIReducer from './redux/api'
import userManagementReducer from './redux/userManagement'
import homeManagementReducer from './redux/homeManagement'

export const store = configureStore({
  reducer: {
    user: UserReducer,
    pages: PagesReducer,
    api: APIReducer,
    userManagement: userManagementReducer,
    homeManagement: homeManagementReducer,
  }
})