import ReactApexChart from "react-apexcharts"
import StatefulWrapper from "../StatefulWrapper"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"
import config from "../../config"
import { Box, IconButton, Stack, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { getEventsByType } from "../../redux/api"
import RefreshIcon from "@mui/icons-material/Refresh"
import DateUtils from "../../utils/dateUtils"

function MemoryGraph(props) {
  const dispatch = useDispatch()
  const eventsResponse = useSelector((state) => state.api.eventsByType)
  const eventsMap = _.groupBy(eventsResponse.value, (event) => event.event_type)
  const device_type = props.device.device_type
  const [currentType, setCurrentType] = useState(
    _.keys(config.GRAPH_TYPES[device_type])[0]
  )
  const values = eventsMap[currentType]?.map((event) => event.event_value) ?? []

  const handleChange = (event, newValue) => {
    setCurrentType(newValue)
  }
  const options = {
    chart: {
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      tickAmount: 6,
      categories:
        eventsMap[currentType]?.map((event) =>
          new Date(event.ts).toLocaleTimeString()
        ) ?? [],
    },
    yaxis: {
      forceNiceScale: true,
      max: Math.max(...values) * 1.05,
      min: Math.min(...values) * 0.95,
      showForNullSeries: false,
      labels: {
        formatter: (value) => {
          return value / 1000 + "KB"
        },
      },
    },
    grid: {
      show: values.length > 0
    },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "top",
      style: {
        fontSize: "22px",
        fontFamily: "Poppins-SemiBold"
      }
    }
  }

  const series = [
    {
      name: config.GRAPH_TYPES[device_type][currentType],
      data: values,
    },
  ]

  return (
    <Box
      sx={{
        boxShadow: 2,
        borderRadius: 5,
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={currentType}
          onChange={handleChange}
          aria-label="memory graph tabs"
        >
          {_.keys(config.GRAPH_TYPES[device_type])?.map((event_type) => {
            return (
              <Tab
                key={event_type}
                label={config.GRAPH_TYPES[device_type][event_type]}
                value={event_type}
              />
            )
          })}
        </Tabs>
        <IconButton
          onClick={() => {
            dispatch(
              getEventsByType({
                params: {
                  start: DateUtils.getDateByAddingHours(-1).toISOString(),
                  event_types: _.keys(config.GRAPH_TYPES[device_type]).toString(),
                },
                pathVariables: {
                  home_id: props.device.home_id,
                  device_id: props.device.device_id,
                },
              })
            )
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Stack>
      <Box sx={{ pt: 2, height: "300px" }}>
        <StatefulWrapper requestState={eventsResponse.state}>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={"300px"}
          />
        </StatefulWrapper>
      </Box>
    </Box>
  )
}

MemoryGraph.propTypes = {
  device: PropTypes.object,
}

export default MemoryGraph
