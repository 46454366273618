const config = {
  METHODS: {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
  },
  PAGES: {
    DASHBOARD_PAGE: "dashboard",
    USERS_PAGE: "users",
    STATS_PAGE: "stats",
    USER_DETAILS_PAGE: "userDetails",
    HOME_DETAILS_PAGE: "homeDetails",
    HOME_DEVICE_PAGE: "homeDevice",
    PROMO_CODES_PAGE: "promoCodes",
  },
  REQUEST_STATE: {
    FULFILLED: "fulfilled",
    PENDING: "pending",
    REJECTED: "rejected",
  },
  GRANULARITY: {
    QUARTER: "quarter_hour",
    HOUR: "hour",
    DAY: "day",
    WEEK: "week",
  },
  EVENT_CODE: {
    PIR: "2004",
    TAG_MOVEMENT: "1010",
    SAT_HUMIDITY: "2007",
    HUB_HUMIDITY: "3007",
    LIGHT: "2001",
    TAG_TEMPERATURE: "1001",
  },
  GRAPH_TYPES: {
    hub: {
      3300: "Internal memory free",
      3303: "SPI memory free",
    },
    sat: {
      2300: "Internal memory free",
      2303: "SPI memory free",
    },
  },
  // using lowercased keys to match values from backend
  USER_ROLE_DISPLAY_NAME: {
    professional_caregiver: "Professional Caregiver",
    recipient: "Care Recipient",
  },
  // using lowercased keys to match values from backend
  DEVICE_TYPE_DISPLAY_NAME: {
    satellitev2: "Satellite",
    satellitev3: "Satellite",
    sat: "Satellite",
    hub_audio: "Hub Audio",
    hub_audiov2: "Hub Audio",
    hub_video: "Hub Video",
    hub_videov2: "Hub Video",
  },
  DEVICE_TYPE: {
    TAG: "tag",
    SATELLITE: "sat",
    HUB: "hub",
    HUB_AUDIO: "hub_audio",
    HUB_AUDIOV2: "hub_audiov2",
    HUB_VIDEO: "hub_video",
    HUB_VIDEOV2: "hub_videov2",
    SATELLITEV2: "satellitev2",
    SATELLITEV3: "satellitev3",
  },
  TIME_IN_MS: {
    SECOND: 1000,
    MINUTE: 1000 * 60,
    HOUR: 1000 * 60 * 60,
    DAY: 1000 * 60 * 60 * 24,
    WEEK: 1000 * 60 * 60 * 24 * 7,
    MONTH_30: 1000 * 60 * 60 * 24 * 30,
    MONTH_31: 1000 * 60 * 60 * 24 * 31,
    YEAR: 1000 * 60 * 60 * 24 * 365,
  },
  MIN_BATTERY_LEVEL: 2000,
  MAX_BATTERY_LEVEL: 3500,
  SUBSCRIPTION: {
    PROMO_CODE_TIERS: {
      RAPID_SOS: "RAPID_SOS",
    },
    PROMO_CODE_TIERS_DISPLAY_TEXT: {
      RAPID_SOS: "PREMIUM",
    },
    TIERS: {
      BASIC: "BASIC",
      RAPID_SOS: "RAPID_SOS",
      RAPID_SOS_PREMIUM_YEARLY: "RAPID_SOS_PREMIUM_YEARLY",
      BASIC_YEARLY: "BASIC_YEARLY",
    },
    TIER_DISPLAY_TEXT: {
      BASIC: "Monthly Basic",
      RAPID_SOS: "Monthly Premium",
      RAPID_SOS_PREMIUM_YEARLY: "Yearly Premium",
      BASIC_YEARLY: "Yearly Basic",
    },
    STATUS_DISPLAY_TEXT: {
      ACTIVE: "Active",
      EXPIRED: "Expired",
      BILLING_RETRY_PERIOD: "Billing retry period",
      GRACE_PERIOD: "Trial",
      REVOKED: "Revoked",
    },
    ECOSYSTEM_DISPLAY_TEXT: {
      PROMO_CODE: "Promo code",
      APPSTORE: "App Store",
      GOOGLE_PLAY: "Google Play",
    },
  },
  USER_GROUPS: {
    SUPER_ADMIN: "SuperAdmin",
    SUPPORT: "Support",
  },
}

export default config
