import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import config from "../../config"
import { useDispatch, useSelector } from "react-redux"
import { generatePromoCodes, resetPromoCodesGenerator } from "../../redux/api"
import _ from "lodash"
import RefreshIcon from "@mui/icons-material/Refresh"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import ErrorIcon from "@mui/icons-material/Error"

function PromoCodesForm(props) {
  const dispatch = useDispatch()
  const generateResponse = useSelector((state) => state.api.generatePromoCodes)
  const [entry, setEntry] = useState({
    name: "",
    tier: config.SUBSCRIPTION.PROMO_CODE_TIERS.RAPID_SOS,
    count: 10,
    requestedBy: "",
    description: "",
    term: 1,
    reason: "",
    created_for: "",
  })
  const [errors, setErrors] = useState([])

  const validateForm = () => {
    const validationErrors = []
    if (entry.name.length === 0) {
      validationErrors.push("name")
    }
    if (entry.created_for.length === 0) {
      validationErrors.push("created_for")
    }
    if (entry.reason.length === 0) {
      validationErrors.push("reason")
    }
    if (entry.requestedBy.length === 0) {
      validationErrors.push("requestedBy")
    }
    if (entry.count < 1 || entry.count > 30) {
      validationErrors.push("count")
    }
    setErrors(validationErrors)
    return validationErrors.length === 0
  }

  const downloadFile = () => {
    const element = document.createElement("a")
    const file = new Blob(
      [generateResponse.value.promo_codes?.toString().replaceAll(",", "\n")],
      { type: "text/plain;charset=utf-8" }
    )
    element.href = URL.createObjectURL(file)
    element.download = "promo_codes.txt"
    document.body.appendChild(element)
    element.click()
    element.remove()
  }

  const displayResponse = () => {
    return (
      <Box
        sx={{
          display: "flex",
          height: "80%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {generateResponse.state === config.REQUEST_STATE.FULFILLED &&
        generateResponse.value.promo_codes ? (
          <IconButton
            onClick={() => {
              downloadFile()
            }}
          >
            <FileDownloadIcon
              sx={{
                color: "green",
                width: "200px",
                height: "200px",
              }}
            />
          </IconButton>
        ) : (
          <ErrorIcon
            sx={{
              color: "red",
              width: "200px",
              height: "200px",
            }}
          />
        )}
      </Box>
    )
  }

  const updateEntry = (event) => {
    const name = event.target.name
    const value = event.target.value
    if (name === "count" && (value < 0 || value > 30)) {
      return
    }
    setEntry({
      ...entry,
      [name]: value,
    })
  }
  return (
    <Box
      sx={{
        width: 1,
        p: 3,
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography align={"left"} variant={"h2"}>
          Generate Promo Codes
        </Typography>
        <IconButton
          onClick={() => {
            dispatch(resetPromoCodesGenerator())
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Stack>
      {generateResponse.state !== null ? (
        displayResponse()
      ) : (
        <Box p={3} component="form" autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                name="name"
                label="Name of Promo Code"
                value={entry.name}
                onChange={(event) => updateEntry(event)}
                helperText="Please fill in the field"
                error={errors.includes("name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                name="requestedBy"
                label="Requested by"
                value={entry.requestedBy}
                onChange={(event) => updateEntry(event)}
                helperText="Please fill in the field"
                error={errors.includes("requestedBy")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="count">
                Number of Promo Codes (max 30) *
              </InputLabel>
              <TextField
                fullWidth
                required
                name="count"
                type="number"
                value={entry.count}
                onChange={(event) => updateEntry(event)}
                helperText={"Must be a number between 1 and 30 inclusive"}
                error={errors.includes("count")}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="term">Term (Number of Months) *</InputLabel>
              <Select
                fullWidth
                required
                name="term"
                value={entry.term}
                onChange={(event) => updateEntry(event)}
              >
                {[...Array(12).keys()].map((val) => (
                  <MenuItem key={val + 1} value={val + 1}>
                    {val + 1}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="tier">Subscription Tier *</InputLabel>
              <Select
                fullWidth
                required
                name="tier"
                value={config.SUBSCRIPTION.PROMO_CODE_TIERS[entry.tier]}
                onChange={(event) => updateEntry(event)}
              >
                {_.keys(config.SUBSCRIPTION.PROMO_CODE_TIERS).map((tier) => {
                  return (
                    <MenuItem key={tier} value={tier}>
                      {
                        config.SUBSCRIPTION.PROMO_CODE_TIERS_DISPLAY_TEXT[
                          config.SUBSCRIPTION.PROMO_CODE_TIERS[tier]
                        ]
                      }
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="created_for"
                label="Created for (Associated Company)"
                value={entry.created_for}
                onChange={(event) => updateEntry(event)}
                helperText="Please fill in the field"
                error={errors.includes("created_for")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="reason"
                label="Reason"
                multiline={true}
                minRows={3}
                value={entry.reason}
                onChange={(event) => updateEntry(event)}
                helperText="Please fill in the field"
                error={errors.includes("reason")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label="Description"
                multiline={true}
                minRows={3}
                value={entry.description}
                onChange={(event) => updateEntry(event)}
              />
            </Grid>
            <Grid item xs={12} pt={6}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  marginTop: "40px",
                  height: "55px",
                  backgroundColor: "rgba(49, 62, 141, 1)",
                }}
                onClick={() => {
                  if (validateForm()) {
                    dispatch(
                      generatePromoCodes({
                        data: {
                          amount: entry.count,
                          params: {
                            tier: entry.tier,
                            duration_days: entry.term * 30,
                            tag: entry.name,
                            created_for: entry.created_for,
                            creation_requested_by: entry.requestedBy,
                            creation_notes: entry.description,
                            creation_reason: entry.reason,
                          },
                        },
                      })
                    )
                  }
                }}
              >
                Generate
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default PromoCodesForm
