import {
  Box,
  Typography,
  Link,
  Checkbox,
  IconButton,
  Badge,
  Stack,
  Tooltip,
} from "@mui/material"
import { setPage } from "../redux/pages"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { setSelectedUser } from "../redux/userManagement"
import config from "../config"
import {
  cancelInvites,
  deactivateTrigger,
  deleteDevices,
  getActiveInvites,
  getEventsByType,
  getHomeDetails,
  removeUsersFromHome,
} from "../redux/api"
import { useEffect, useState } from "react"
import BackButton from "../components/BackButton"
import RefreshIcon from "@mui/icons-material/Refresh"
import WarningIcon from "@mui/icons-material/Warning"
import { removeSelectedHome, setSelectedDevice } from "../redux/homeManagement"
import ClickToCopyButton from "../components/ClickToCopyButton"
import StatefulButton from "../components/StatefulButton"
import StatefulTable from "../components/StatefulTable"
import TimelineChart from "../components/TimelineChart"
import OTAForm from "../components/OTAForm"
import DateUtils from "../utils/dateUtils"
import Subscription from "../components/Subscription"
import StarIcon from "@mui/icons-material/Star"
import { yellow } from "@mui/material/colors"
import { isAdminSelector } from "../redux/selectors"

function HomeDetails(props) {
  const dispatch = useDispatch()
  const homeDetails = useSelector((state) => state.api.homeDetails)
  const isRequestDone = homeDetails.state === config.REQUEST_STATE.FULFILLED
  const activeInvitesResponse = useSelector((state) => state.api.activeInvites)
  const selectedHomes = useSelector(
    (state) => state.homeManagement.selectedHomes
  )
  const isAdmin = useSelector(isAdminSelector)
  const [selectedUsers, setSelectedUsers] = useState({
    users: [],
    isRemoving: false,
  })
  const [selectedDevices, setSelectedDevices] = useState({
    devices: [],
    isRemoving: false,
  })
  const [selectedInvites, setSelectedInvites] = useState([])
  const [isDeactivatingTrigger, setIsDeactivatingTrigger] = useState(false)

  if (isRequestDone) {
    if (isDeactivatingTrigger) {
      setIsDeactivatingTrigger(false)
    }
    if (selectedDevices.isRemoving) {
      setSelectedDevices({ ...selectedDevices, isRemoving: false })
    }
    if (selectedUsers.isRemoving) {
      setSelectedUsers({ ...selectedUsers, isRemoving: false })
    }
  }

  const home_id = selectedHomes[selectedHomes.length - 1]

  let usersData = []
  let devicesData = []
  let triggersData = []
  let subscriptionData = {}
  let timezoneData = {}
  if (homeDetails.value[home_id]) {
    usersData = homeDetails.value[home_id].users?.map((user) => {
      return {
        id: user.Username,
        home_id: user.home_id,
        homes: user.homes,
        confirmed: user.confirmed,
        user_role: user.user_role,
        joined_home_utc: user.joined_home_utc,
        created: user.UserCreateDate,
        last_modified: user.UserLastModifiedDate,
        ..._.fromPairs(
          user.UserAttributes?.map(({ Name, Value }) => [Name, Value])
        ),
      }
    })
    devicesData = homeDetails.value[home_id].devices
    triggersData = homeDetails.value[home_id].triggers
    subscriptionData = homeDetails.value[home_id].subscription
    timezoneData = homeDetails.value[home_id].timezone
  }
  const activeInvites = activeInvitesResponse.value[home_id]

  useEffect(() => {
    dispatch(
      getActiveInvites({
        params: { home_id: home_id },
        id: home_id,
      })
    )
    if (!homeDetails.value[home_id]) {
      dispatch(
        getHomeDetails({
          params: { home_id: home_id },
          id: home_id,
        })
      )
    }
  }, [dispatch, home_id, homeDetails.value])

  const onUserCheckboxPressed = (userID) => {
    if (selectedUsers.users.includes(userID)) {
      setSelectedUsers({
        ...selectedUsers,
        users: selectedUsers.users.filter((currentID) => currentID !== userID),
      })
    } else {
      setSelectedUsers({
        ...selectedUsers,
        users: [...selectedUsers.users, userID],
      })
    }
  }

  const onDeviceCheckboxPressed = (deviceID) => {
    if (selectedDevices.devices.includes(deviceID)) {
      setSelectedDevices({
        ...selectedDevices,
        devices: selectedDevices.devices.filter(
          (currentID) => currentID !== deviceID
        ),
      })
    } else {
      setSelectedDevices({
        ...selectedDevices,
        devices: [...selectedDevices.devices, deviceID],
      })
    }
  }

  const onInviteCheckboxPressed = (inviteID) => {
    if (selectedInvites.includes(inviteID)) {
      setSelectedInvites(
        selectedInvites.filter((currentID) => currentID !== inviteID)
      )
    } else {
      setSelectedInvites([...selectedInvites, inviteID])
    }
  }

  const displayHomeEnvironment = () => {
    if (devicesData.length === 0) {
      return false
    }
    const environments = _.chain(devicesData)
      .map((device) => device.env)
      .filter((env) => env !== null)
      .uniq()
      .value()
    if (environments.length === 1) {
      return (
        <Typography
          fontStyle={"italic"}
          color={"rgba(191, 135, 4, 0.7)"}
          variant={"h3"}
        >
          {environments[0]}
        </Typography>
      )
    } else {
      return (
        <Tooltip
          title={
            environments.length === 0
              ? "No environment set"
              : "Multiple environments set"
          }
        >
          <WarningIcon color={"warning"} />
        </Tooltip>
      )
    }
  }

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 4,
      }}
    >
      <BackButton onBack={() => dispatch(removeSelectedHome())} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack direction={"row"} spacing={2}>
          <Typography variant={"h1"}>Home</Typography>
          {displayHomeEnvironment()}
        </Stack>
        <IconButton
          onClick={() => {
            dispatch(
              getHomeDetails({
                params: { home_id: home_id },
                id: home_id,
              })
            )
            dispatch(
              getActiveInvites({
                params: { home_id: home_id },
                id: home_id,
              })
            )
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <ClickToCopyButton copyText={home_id}>
        <Typography variant={"caption"}>ID#:{home_id}</Typography>
      </ClickToCopyButton>
      <Box py={2}>
        {isRequestDone && <Subscription subscription={subscriptionData} />}
      </Box>
      <StatefulTable
        isRequestDone={
          isRequestDone ||
          (!selectedUsers.isRemoving &&
            (selectedDevices.isRemoving || isDeactivatingTrigger))
        }
        title="Users"
        columns={["Name", "Email", "Phone", "Role", "Joined Date"].concat(
          isAdmin
            ? [
                <StatefulButton
                  title={"Remove"}
                  color={"error"}
                  isLoading={!isRequestDone && selectedUsers.isRemoving}
                  disabled={selectedUsers.users.length === 0}
                  onStartRequest={() => {
                    setSelectedUsers({
                      users: [],
                      isRemoving: true,
                    })
                    dispatch(
                      removeUsersFromHome({
                        data: {
                          user_ids: selectedUsers.users,
                          home_id: home_id,
                        },
                        isDelete: true,
                        id: home_id,
                      })
                    )
                  }}
                />,
              ]
            : []
        )}
        rows={usersData?.map((user) => {
          return [
            <Stack direction="row" spacing={1} justifyContent={"center"}>
              <Link
                onClick={() => {
                  dispatch(setSelectedUser(user))
                  dispatch(setPage(config.PAGES.USER_DETAILS_PAGE))
                }}
                href="#"
                underline="hover"
              >
                {user.name}
              </Link>
              {user.id === subscriptionData?.owner_user_id ? (
                <Tooltip title={"Subscription Owner"}>
                  <StarIcon
                    sx={{
                      color: yellow["A700"],
                    }}
                  />
                </Tooltip>
              ) : (
                false
              )}
            </Stack>,
            user.email,
            user.phone_number,
            config.USER_ROLE_DISPLAY_NAME[user.user_role] || user.user_role,
            user.joined_home_utc
              ? new Date(user.joined_home_utc).toLocaleDateString()
              : null,
          ].concat(
            isAdmin
              ? [
                  <Checkbox
                    checked={selectedUsers.users.includes(user.id)}
                    onChange={() => onUserCheckboxPressed(user.id)}
                  />,
                ]
              : []
          )
        })}
      />
      <Box mt={10} mb={10}>
        <Typography variant={"h6"}>ADL Timeline</Typography>
        <TimelineChart home_id={home_id} timezone={timezoneData?.tz} />
      </Box>
      <Box mt={10} mb={10}>
        <StatefulTable
          isRequestDone={
            isRequestDone ||
            (!selectedDevices.isRemoving &&
              (selectedUsers.isRemoving || isDeactivatingTrigger))
          }
          title="Devices"
          columns={[
            "Device Identifier",
            "Name",
            "Room",
            "Device Type",
            "Environment",
          ].concat(
            isAdmin
              ? [
                  "OTA update",
                  <StatefulButton
                    title={"Delete"}
                    color={"error"}
                    isLoading={!isRequestDone && selectedDevices.isRemoving}
                    disabled={selectedDevices.devices.length === 0}
                    onStartRequest={() => {
                      setSelectedDevices({
                        devices: [],
                        isRemoving: true,
                      })
                      dispatch(
                        deleteDevices({
                          data: {
                            device_ids: selectedDevices.devices,
                            home_id: home_id,
                          },
                          isDelete: true,
                          id: home_id,
                        })
                      )
                    }}
                  />,
                ]
              : []
          )}
          rows={devicesData?.map((device) => {
            return [
              <Link
                onClick={() => {
                  dispatch(
                    setSelectedDevice({
                      ...device,
                      timezone: homeDetails.value[home_id].timezone?.tz,
                    })
                  )
                  dispatch(setPage(config.PAGES.HOME_DEVICE_PAGE))
                  if (config.GRAPH_TYPES[device.device_type]) {
                    dispatch(
                      getEventsByType({
                        params: {
                          start:
                            DateUtils.getDateByAddingHours(-1).toISOString(),
                          event_types: _.keys(
                            config.GRAPH_TYPES[device.device_type]
                          ).toString(),
                        },
                        pathVariables: {
                          home_id: device.home_id,
                          device_id: device.device_id,
                        },
                      })
                    )
                  }
                }}
                href="#"
                underline="hover"
              >
                {device.device_id}
              </Link>,
              device.device_name,
              device.room_name,
              device.device_type,
              device.env,
            ]
              .concat(
                isAdmin && device.device_type !== config.DEVICE_TYPE.TAG
                  ? [<OTAForm device={device} />]
                  : [null]
              )
              .concat(
                isAdmin
                  ? [
                      <Checkbox
                        checked={selectedDevices.devices.includes(
                          device.device_id
                        )}
                        onChange={() =>
                          onDeviceCheckboxPressed(device.device_id)
                        }
                      />,
                    ]
                  : [null]
              )
          })}
        />
      </Box>
      <Box mt={10} mb={10}>
        <StatefulTable
          isRequestDone={
            isRequestDone ||
            selectedUsers.isRemoving ||
            isDeactivatingTrigger ||
            selectedDevices.isRemoving
          }
          title="Triggers"
          columns={[
            "Trigger Identifier",
            "Name",
            "Alert Level",
            "System Name",
            "",
          ]}
          rows={triggersData?.map((trigger) => {
            return [
              trigger.is_active ? (
                <Badge badgeContent={"active"} color={"success"}>
                  {trigger.trigger_id}
                </Badge>
              ) : (
                trigger.trigger_id
              ),
              trigger.name,
              trigger.alert_level,
              trigger.system_name,
              isAdmin && trigger.is_active && (
                <StatefulButton
                  title={"Deactivate"}
                  color={"error"}
                  disabled={!isRequestDone}
                  onStartRequest={() => {
                    setIsDeactivatingTrigger(true)
                    dispatch(
                      deactivateTrigger({
                        pathVariables: {
                          home_id: trigger.home_id,
                          trigger_id: trigger.trigger_id,
                        },
                        id: trigger.home_id,
                      })
                    )
                  }}
                />
              ),
            ]
          })}
        />
      </Box>
      {activeInvites && activeInvites.invites.length > 0 && (
        <StatefulTable
          title="Active Invites"
          columns={[
            "Invite Identifier",
            "Invitee",
            "Invitee Role",
            "Expires at",
          ].concat(
            isAdmin
              ? [
                  <StatefulButton
                    title={"Cancel"}
                    color={"error"}
                    isLoading={
                      activeInvitesResponse.state ===
                      config.REQUEST_STATE.PENDING
                    }
                    disabled={selectedInvites.length === 0}
                    onStartRequest={() => {
                      setSelectedInvites([])
                      dispatch(
                        cancelInvites({
                          data: {
                            home_id: home_id,
                            invite_ids: selectedInvites,
                          },
                          isDelete: true,
                          id: home_id,
                        })
                      )
                    }}
                  />,
                ]
              : []
          )}
          rows={activeInvites.invites.map((invite) => {
            return [
              invite.invite_id,
              invite.invitee_email,
              invite.invitee_role,
              invite.invite_expires
                ? new Date(invite.invite_expires).toLocaleDateString()
                : null,
            ].concat(
              isAdmin
                ? [
                    <Checkbox
                      checked={selectedInvites.includes(invite.invite_id)}
                      onChange={() => onInviteCheckboxPressed(invite.invite_id)}
                    />,
                  ]
                : []
            )
          })}
        />
      )}
    </Box>
  )
}

export default HomeDetails
