import { Box, Modal } from "@mui/material"
import PropTypes from "prop-types"

function CommonModal(props) {
  return (
    <Modal open={props.show} onClose={props.onClose}>
      <Box
        sx={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          boxShadow: 10,
          top: "50%",
          left: "50%",
          width: "80%",
          height: "80%",
          overflow: "scroll",
          backgroundColor: "white",
          borderRadius: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.children}
      </Box>
    </Modal>
  )
}

CommonModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

export default CommonModal
