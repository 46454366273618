import PropTypes from "prop-types"
import CardMembershipIcon from "@mui/icons-material/CardMembership"
import { Stack, Typography } from "@mui/material"
import config from "../../config"
import { grey, indigo } from "@mui/material/colors"

function Subscription(props) {
  const subscription = props.subscription
  const color = subscription?.owner_user_id ? indigo[500] : grey[700]
  const tier =
    config.SUBSCRIPTION.TIER_DISPLAY_TEXT[subscription?.tier] ?? "No Tier"
  const status =
    config.SUBSCRIPTION.STATUS_DISPLAY_TEXT[subscription?.status] ?? "No Status"
  const ecosystem =
    config.SUBSCRIPTION.ECOSYSTEM_DISPLAY_TEXT[subscription?.ecosystem] ??
    "No Ecosystem"
  const validSubscriptionText = `${tier} (${status}) - ${ecosystem}`
  const subscriptionText = subscription?.owner_user_id
    ? subscription?.status
      ? validSubscriptionText
      : "Subscription not authorized"
    : "No subscription details"
  return (
    <Stack direction="row" spacing={1}>
      <CardMembershipIcon sx={{ color }} />
      {
        <Typography variant={"h3"} color={color}>
          {subscriptionText}
        </Typography>
      }
    </Stack>
  )
}

Subscription.propTypes = {
  subscription: PropTypes.object,
}

export default Subscription
