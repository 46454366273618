import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

function StatefulTable(props) {
  const noDataRow = () => {
    return (
      <TableRow>
        <TableCell
          colSpan={props.columns.length}
          align="center"
          sx={{ color: "text.disabled" }}
        >
          No data available.
        </TableCell>
      </TableRow>
    )
  }

  const displayRows = (rows) => {
    return rows.map((row, index) => {
      return (
        <TableRow key={index}>
          {row.map((cell, index) => (
            <TableCell key={index} align="center">
              {cell}
            </TableCell>
          ))}
        </TableRow>
      )
    })
  }

  const displayData = () => {
    if (props.rows && props.rows.length > 0) {
      return displayRows(props.rows)
    } else if (props.groupedRows && props.groupedRows.length > 0) {
      return props.groupedRows.map((group, index) => (
        <React.Fragment key={index}>
          <TableRow>
            <TableCell colSpan={props.columns.length}>
              {props.groupSubtitle && (
                <Typography fontWeight="bold">
                  {props.groupSubtitle}:
                </Typography>
              )}
              {group.subtitle}
            </TableCell>
          </TableRow>
          {displayColumns()}
          {group.rows.length > 0 ? displayRows(group.rows) : noDataRow()}
        </React.Fragment>
      ))
    } else {
      return noDataRow()
    }
  }

  const displayColumns = () => {
    return (
      props.columns.length > 0 && (
        <TableRow>
          {props.columns.map((name) => (
            <TableCell
              align="center"
              key={name}
              sx={{
                fontWeight: "bold",
              }}
            >
              {name}
            </TableCell>
          ))}
        </TableRow>
      )
    )
  }

  return (
    <Table>
      {props.title && (
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={props.columns.length}>
              <Typography variant="h6">{props.title}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {props.rows && displayColumns()}
        {props.isRequestDone ? (
          displayData()
        ) : (
          <TableRow>
            <TableCell colSpan={props.columns.length} align="center">
              {props.didFail ? (
                <Typography color={"red"}>Failed to fetch data!</Typography>
              ) : (
                <CircularProgress size={25} />
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

StatefulTable.propTypes = {
  isRequestDone: PropTypes.bool,
  columns: PropTypes.array,
  title: PropTypes.string,
  rows: PropTypes.array,
  groupedRows: PropTypes.array,
  groupSubtitle: PropTypes.string,
  didFail: PropTypes.bool,
}

StatefulTable.defaultProps = {
  didFail: false,
  isRequestDone: true,
  title: "",
  columns: [],
}

export default StatefulTable
