import PropTypes from "prop-types"
import StatefulTable from "../StatefulTable"
import { CircularProgress, Container, Typography } from "@mui/material"
import config from "../../config"
import React from "react"

function StatefulWrapper(props) {
  return (
    <Container>
      {props.requestState === config.REQUEST_STATE.PENDING ? (
        <CircularProgress size={25} />
      ) : props.requestState === config.REQUEST_STATE.REJECTED ? (
        <Typography variant={"h4"} color={"red"}>
          Failed to fetch data!
        </Typography>
      ) : (
        props.children
      )}
    </Container>
  )
}

StatefulTable.propTypes = {
  requestState: PropTypes.object,
}

export default StatefulWrapper
