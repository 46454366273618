import {
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material"
import VerifiedIcon from "../VerifiedIcon"

function KeyValueTableRow(props) {
  if (!props.value && props.checkValue) return null
  return (
    <TableRow
      sx={
        props.isLastRow
          ? {
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }
          : {}
      }
    >
      <TableCell>
        <Typography fontWeight={"bold"} align={"right"}>
          {props.title}
        </Typography>
      </TableCell>
      <TableCell
        sx={
          props.isVerified != null
            ? { display: "flex", flexDirection: "row" }
            : {}
        }
      >
        <Typography>{props.value}</Typography>
        {props.isVerified != null && (
          <VerifiedIcon isVerified={props.isVerified} />
        )}
      </TableCell>
    </TableRow>
  )
}

export default KeyValueTableRow
