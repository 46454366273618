import { Box, Stack, Table, TableBody, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import BackButton from "../components/BackButton"
import KeyValueTableRow from "../components/KeyValueTableRow"
import HeatmapGrid from "../components/HeatmapGrid"
import config from "../config"
import LineChart from "../components/LineChart"
import OTAForm from "../components/OTAForm"
import Battery from "../components/Battery"
import IconButtonWithText from "../components/IconButtonWithText"
import { rebootDevice } from "../redux/api"
import DeviceState from "../components/DeviceState"
import MemoryGraph from "../components/MemoryGraph"
import { isAdminSelector } from "../redux/selectors"

function HomeDevice(props) {
  const selectedDevice = useSelector(
    (state) => state.homeManagement.selectedDevice
  )
  const rebootDeviceResponse = useSelector((state) => state.api.rebootDevice)
  const dispatch = useDispatch()
  const isAdmin = useSelector(isAdminSelector)

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 4,
      }}
    >
      <Box>
        <BackButton />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 2,
            paddingX: 5,
          }}
        >
          <Stack direction={"row"} spacing={3}>
            {isAdmin &&
              (selectedDevice.device_type === config.DEVICE_TYPE.HUB ||
                selectedDevice.device_type ===
                  config.DEVICE_TYPE.SATELLITE) && (
                <IconButtonWithText
                  title={"Reboot"}
                  onClick={() =>
                    dispatch(
                      rebootDevice({
                        data: {
                          home_id: selectedDevice.home_id,
                          device_id: selectedDevice.device_id,
                        },
                      })
                    )
                  }
                  isDisabled={
                    rebootDeviceResponse.state === config.REQUEST_STATE.PENDING
                  }
                  didFail={
                    rebootDeviceResponse.state === config.REQUEST_STATE.REJECTED
                  }
                />
              )}
            <Typography variant={"h1"}>{selectedDevice.device_name}</Typography>
          </Stack>
          {selectedDevice.device_type !== config.DEVICE_TYPE.TAG ? (
            isAdmin && <OTAForm device={selectedDevice} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Battery
                level={
                  (selectedDevice.battery_level - config.MIN_BATTERY_LEVEL) /
                  (config.MAX_BATTERY_LEVEL - config.MIN_BATTERY_LEVEL)
                }
              />
              <Typography fontWeight="bold" fontSize={12}>
                {selectedDevice.battery_level / 1000}V
              </Typography>
            </Box>
          )}
        </Box>
        <Table>
          <TableBody>
            <KeyValueTableRow
              title="Device Identifier"
              value={selectedDevice.device_id}
            />
            <KeyValueTableRow
              title="Device Name"
              value={selectedDevice.device_name}
            />
            <KeyValueTableRow
              title="Home Identifier"
              value={selectedDevice.home_id}
            />
            <KeyValueTableRow
              title="Room Name"
              value={selectedDevice.room_name}
            />
            <KeyValueTableRow title="Environment" value={selectedDevice.env} />
            {selectedDevice.firmware?.map((firmware) => (
              <KeyValueTableRow
                key={firmware.device_type}
                title={`${
                  config.DEVICE_TYPE_DISPLAY_NAME[firmware.device_type]
                } Firmware Version`}
                value={firmware.firmware_version}
              />
            ))}
          </TableBody>
        </Table>
        <Stack direction={{ md: "row", xs: "column" }} pt={8} spacing={5}>
          <DeviceState device={selectedDevice} />
          {(selectedDevice.device_type === config.DEVICE_TYPE.HUB ||
            selectedDevice.device_type === config.DEVICE_TYPE.SATELLITE) && (
            <Box sx={{ width: "100%" }}>
              <MemoryGraph device={selectedDevice} />
            </Box>
          )}
        </Stack>
      </Box>
      {selectedDevice.device_type === config.DEVICE_TYPE.SATELLITE && (
        <Box pb="250px">
          <Box pt={20}>
            <Typography variant={"h6"}>PIR Motion Heatmap</Typography>
            <HeatmapGrid code={config.EVENT_CODE.PIR} />
          </Box>
          <LineChart
            codes={[config.EVENT_CODE.LIGHT, config.EVENT_CODE.SAT_HUMIDITY]}
          />
        </Box>
      )}
      {selectedDevice.device_type === config.DEVICE_TYPE.TAG && (
        <Box pb="250px">
          <Box pt={20}>
            <Typography variant={"h6"}>Tag Movement Heatmap</Typography>
            <HeatmapGrid code={config.EVENT_CODE.TAG_MOVEMENT} />
          </Box>
          <LineChart codes={[config.EVENT_CODE.TAG_TEMPERATURE]} />
        </Box>
      )}
      {selectedDevice.device_type === config.DEVICE_TYPE.HUB && (
        <Box pb="250px">
          <LineChart codes={[config.EVENT_CODE.HUB_HUMIDITY]} />
        </Box>
      )}
    </Box>
  )
}

export default HomeDevice
