import config from "../../config"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { useState } from "react"

function StatefulButton(props) {
  const [canDoRequest, setCanDoRequest] = useState(true)
  const resetState = () => {
    if (!props.onResetState) {
      setCanDoRequest(true)
    } else {
      props.onResetState()
    }
  }
  const startRequest = () => {
    if (!props.onResetState) {
      setCanDoRequest(false)
    }
    props.onStartRequest()
  }

  return (
    <Box sx={{ justifyContent: "center", minWidth: "100px" }}>
      {props.requestState === config.REQUEST_STATE.REJECTED &&
      !props.isLoading &&
      (!canDoRequest || props.onResetState) ? (
        <Typography
          onClick={resetState}
          sx={{ color: "error.main", fontWeight: "bold" }}
        >
          Error
        </Typography>
      ) : props.requestState === config.REQUEST_STATE.FULFILLED &&
        !props.isLoading &&
        (!canDoRequest || props.onResetState) ? (
        <Typography
          onClick={resetState}
          sx={{ color: "success.main", fontWeight: "bold" }}
        >
          Success
        </Typography>
      ) : props.requestState === config.REQUEST_STATE.PENDING ||
        props.isLoading ? (
        <CircularProgress size={31} />
      ) : (
        <Button
          sx={{
            height: "40px",
          }}
          color={props.color}
          variant={"contained"}
          onClick={startRequest}
          disabled={props.disabled}
        >
          {props.title}
        </Button>
      )}
    </Box>
  )
}

StatefulButton.propTypes = {
  requestState: PropTypes.string,
  onResetState: PropTypes.func,
  onStartRequest: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default StatefulButton
