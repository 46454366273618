import "./index.css"
import { Box } from "@mui/material"
import util from "../../utils/util"
import PropTypes from "prop-types"

function ClickToCopyButton(props) {
  return (
    <Box
      textAlign="left"
      onClick={() => util.copyToClipboard(props.copyText)}
      className={"clickable-box"}
    >
      {props.children}
    </Box>
  )
}

ClickToCopyButton.propTypes = {
  copyText: PropTypes.string,
  children: PropTypes.any,
}

export default ClickToCopyButton
