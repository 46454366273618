import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { importPromoCodes, resetPromoCodesImporter } from "../../redux/api"
import RefreshIcon from "@mui/icons-material/Refresh"
import React, { useEffect, useState } from "react"
import config from "../../config"
import _ from "lodash"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import FailureCircleIcon from "@mui/icons-material/Dangerous"

function ImportPromoCodesForm(props) {
  const dispatch = useDispatch()
  const importResponse = useSelector((state) => state.api.importPromoCodes)
  const [promoCodes, setPromoCodes] = useState([
    {
      promo_code: "",
      tier: config.SUBSCRIPTION.PROMO_CODE_TIERS.RAPID_SOS,
      duration_days: 12 * 30,
      created_for: "AMAZON",
    },
  ])
  useEffect(() => {
    dispatch(resetPromoCodesImporter())
  }, [dispatch])

  const updatePromoCode = (event, index) => {
    const name = event.target.name
    const value =
      name === "promo_code"
        ? event.target.value.toUpperCase()
        : event.target.value
    const newPromoCodes = promoCodes.map((code, i) => {
      if (i === index) {
        return {
          ...code,
          [name]: value,
        }
      } else {
        return code
      }
    })
    setPromoCodes(newPromoCodes)
  }

  const addPromoCode = () => {
    const lastCode = promoCodes[promoCodes.length - 1]
    setPromoCodes([
      ...promoCodes,
      {
        ...lastCode,
        promo_code: "",
      },
    ])
  }

  const removePromoCode = (index) => {
    setPromoCodes(promoCodes.filter((code, i) => i !== index))
  }

  const resetPromoCodes = () => {
    const lastCode = promoCodes[promoCodes.length - 1]
    setPromoCodes([
      {
        ...lastCode,
        promo_code: "",
      },
    ])
  }

  const emptyCodeExists = () => {
    return promoCodes.find((code) => code.promo_code.length === 0) !== undefined
  }

  const duplicatedCodeExists = () => {
    return (
      promoCodes.filter((code) => {
        return code.promo_code === promoCodes[promoCodes.length - 1].promo_code
      }).length > 1
    )
  }

  const disableButtons =
    emptyCodeExists() || duplicatedCodeExists() || importResponse.state !== null

  const renderErrorText = (text) => {
    return (
      <Typography variant={"h5"} fontSize={"0.8rem"} color={"error"}>
        {text}
      </Typography>
    )
  }

  const renderPromoCodeState = (index) => {
    if (importResponse.state === null) {
      return (
        index < promoCodes.length - 1 && (
          <IconButton
            color="error"
            size="large"
            onClick={() => removePromoCode(index)}
          >
            <RemoveCircleIcon fontSize="large" />
          </IconButton>
        )
      )
    } else if (importResponse.state === config.REQUEST_STATE.PENDING) {
      return <CircularProgress size={25} />
    } else {
      const code = promoCodes[index].promo_code
      return (
        code.length > 0 &&
        (importResponse.value?.inserted?.includes(code) ? (
          <CheckCircleIcon fontSize="large" color="success" />
        ) : (
          <FailureCircleIcon fontSize="large" color="error" />
        ))
      )
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        p: 3,
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Typography align={"left"} variant={"h2"}>
          Import Promo Codes
        </Typography>
        <IconButton
          onClick={() => {
            dispatch(resetPromoCodesImporter())
            resetPromoCodes()
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Stack>
      <Box px={2} py={6} component="form" autoComplete="off">
        {promoCodes?.map((code, index) => {
          return (
            <Box key={index} pb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputLabel id="promo_code">Promo Code</InputLabel>
                  <TextField
                    fullWidth
                    required
                    name="promo_code"
                    placeholder="Order ID"
                    value={code.promo_code}
                    onChange={(event) => updatePromoCode(event, index)}
                    helperText="Please fill in the field"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel id="duration_days">
                    Term (Number of Months)
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    name="duration_days"
                    value={code.duration_days}
                    onChange={(event) => updatePromoCode(event, index)}
                  >
                    {[...Array(12).keys()].map((val) => (
                      <MenuItem key={val + 1} value={(val + 1) * 30}>
                        {val + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={10} sm={4}>
                  <InputLabel id="tier">Subscription Tier</InputLabel>
                  <Select
                    fullWidth
                    required
                    name="tier"
                    value={config.SUBSCRIPTION.PROMO_CODE_TIERS[code.tier]}
                    onChange={(event) => updatePromoCode(event, index)}
                  >
                    {_.keys(config.SUBSCRIPTION.PROMO_CODE_TIERS).map(
                      (tier) => {
                        return (
                          <MenuItem key={tier} value={tier}>
                            {
                              config.SUBSCRIPTION
                                .PROMO_CODE_TIERS_DISPLAY_TEXT[
                                config.SUBSCRIPTION.PROMO_CODE_TIERS[tier]
                              ]
                            }
                          </MenuItem>
                        )
                      }
                    )}
                  </Select>
                </Grid>
                <Grid item xs={1} p={2} display="flex" alignItems="center">
                  {renderPromoCodeState(index)}
                </Grid>
              </Grid>
            </Box>
          )
        })}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            color="success"
            disabled={disableButtons || promoCodes.length >= 30}
            size="large"
            onClick={addPromoCode}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box
          sx={{
            marginTop: "60px",
          }}
        >
          {emptyCodeExists() &&
            renderErrorText("Please fill out all the promo codes.")}
          {duplicatedCodeExists() &&
            renderErrorText("Please remove the duplicates.")}
          {promoCodes.length >= 30 &&
            renderErrorText("Maximum number of promo codes is 30.")}
          {importResponse.state !== null &&
            renderErrorText(
              "Please press the refresh button in the top right corner to start again."
            )}
          <Button
            fullWidth
            disabled={disableButtons}
            variant="contained"
            sx={{
              height: "55px",
              backgroundColor: "rgba(49, 62, 141, 1)",
            }}
            onClick={() => {
              dispatch(
                importPromoCodes({
                  data: {
                    promo_codes: promoCodes,
                  },
                })
              )
            }}
          >
            Import
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ImportPromoCodesForm
