import { TableCell, TableRow } from "@mui/material"

function CenteredTableRow(props) {
  return (
    <TableRow>
      <TableCell align="center">{props.children}</TableCell>
    </TableRow>
  )
}

export default CenteredTableRow
