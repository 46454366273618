import { Box, Button } from "@mui/material"
import { goBack } from "../../redux/pages"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"

function BackButton(props) {
  const dispatch = useDispatch()

  return (
    <Box display="flex" justifyContent="flex-start" pb={2}>
      <Button
        color={"primary"}
        variant={"text"}
        onClick={() => {
          if (props.onBack) {
            props.onBack()
          }
          dispatch(goBack())
        }}
      >
        {"< Back"}
      </Button>
    </Box>
  )
}

BackButton.propTypes = {
  onBack: PropTypes.func,
}

export default BackButton
