import { createSlice } from "@reduxjs/toolkit"
import config from "../config"

const initialState = {
  pagesStack: [],
  currentPage: config.PAGES.DASHBOARD_PAGE,
  pageState: {},
}

const slice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPage(state, action) {
      return {
        pagesStack: [...state.pagesStack, state.currentPage],
        currentPage: action.payload,
      }
    },
    goBack(state) {
      return {
        pagesStack: state.pagesStack.filter(
          (_, i) => i !== state.pagesStack.length - 1
        ),
        currentPage: state.pagesStack[state.pagesStack.length - 1],
      }
    },
  },
})

export const { setPage, goBack } = slice.actions
export default slice.reducer
