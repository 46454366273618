import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)

class dateUtils {
  static getDateByAddingHours = (hours, date = new Date()) => {
    date.setUTCHours(date.getUTCHours() + hours)
    return date
  }

  static getDateDifferenceInMonths = (startDate, endDate) => {
    return (
      (endDate?.getFullYear() - startDate?.getFullYear()) * 12 -
      startDate?.getMonth() +
      endDate?.getMonth()
    )
  }

  static getDateDifferenceInDays = (startDate, endDate) => {
    return Math.floor(
      (new Date(endDate) - new Date(startDate)) / (24 * 60 * 60 * 1000)
    )
  }

  static getDateDifferenceInYear = (startDate, endDate) => {
    return endDate?.getFullYear() - startDate?.getFullYear()
  }

  static getLocalAndRemoteTimezonesOffset = (date, timezone) => {
    if (timezone && date) {
      const remoteDate = dayjs().tz(timezone)
      const utcTimezoneOffset = parseInt(remoteDate.format("Z"))
      const utcOffset = date.getTimezoneOffset() / 60
      return utcTimezoneOffset + utcOffset
    }
    return 0
  }

  static overrideTimezoneInfo = (date, timezone) => {
    if (timezone) {
      const hoursOffset = dateUtils.getLocalAndRemoteTimezonesOffset(
        date,
        timezone
      )
      const convertedDate = new Date(date)
      convertedDate.setHours(date.getHours() - hoursOffset)
      return convertedDate
    }

    return date
  }
}

export default dateUtils
