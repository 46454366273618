import { createSlice } from "@reduxjs/toolkit"

const initialState = { selectedDevice: null, selectedHomes: [] }

const slice = createSlice({
  name: "homeManagement",
  initialState,
  reducers: {
    setSelectedDevice(state, action) {
      return { ...state, selectedDevice: action.payload }
    },
    setSelectedHome(state, action) {
      return {
        ...state,
        selectedHomes: [...state.selectedHomes, action.payload],
      }
    },
    removeSelectedHome(state) {
      return {
        ...state,
        selectedHomes: state.selectedHomes.filter(
          (_, i) => i !== state.selectedHomes.length - 1
        ),
      }
    },
  },
})

export const { setSelectedDevice, setSelectedHome, removeSelectedHome } =
  slice.actions
export default slice.reducer
