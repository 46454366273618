import React from "react"
import ErrorIcon from "@mui/icons-material/Error"
import PropTypes from "prop-types"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hideError) {
      this.setState({ hasError: false })
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError && !this.props.hideError) {
      return <ErrorIcon color={"error"} />
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  hideError: PropTypes.bool,
}

export default ErrorBoundary
