import "./index.css"
import Rectangle from "../Rectangle"
import { Stack, TableCell, TableRow, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import config from "../../config"
import { getEventStats } from "../../redux/api"
import PropTypes from "prop-types"
import dateUtils from "../../utils/dateUtils"
import GraphContainer from "../GraphContainer"
import DateUtils from "../../utils/dateUtils"

function HeatmapGrid(props) {
  const dispatch = useDispatch()
  const [granularity, setGranularity] = useState(config.GRANULARITY.DAY)
  const [shouldScroll, setShouldScroll] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const eventStatsResponse = useSelector((state) => state.api.eventStats)
  const selectedDevice = useSelector(
    (state) => state.homeManagement.selectedDevice
  )
  const [aggregationType, setAggregationType] = useState("avg")
  const requestID = props.code + selectedDevice.device_id

  const fullEventData = eventStatsResponse.value[requestID]?.values[props.code]

  const eventData = fullEventData
    ? fullEventData.reduce((acc, event) => {
        return {
          ...acc,
          [event.date_chunk]: {
            avg: event.avg_event_value,
            sum: event.sum_event_value,
            cnt: event.num_events,
            min: event.min_event_value,
            max: event.max_event_value,
          },
        }
      }, {})
    : []

  let allValues = []
  Object.keys(eventData).forEach((key) => {
    allValues.push(eventData[key][aggregationType])
  })
  let max = Math.max(...allValues)

  let style = { flexWrap: "wrap" }
  if (shouldScroll) {
    style = {
      flexWrap: "nowrap",
    }
  }

  const getTooltipDateLabel = (date) => {
    switch (granularity) {
      case config.GRANULARITY.QUARTER:
      case config.GRANULARITY.HOUR:
        return date.toLocaleString("en-US", {
          timeZone: selectedDevice.timezone,
        })
      default:
        return date.toISOString().split("T")[0]
    }
  }

  const createRow = (columnsCount, date, zeroCheck, endCheck, helpDate) => {
    let row = []
    for (let j = 0; j < columnsCount; j++) {
      let newDate = new Date(date)
      switch (granularity) {
        case config.GRANULARITY.QUARTER:
          let minutes = j * 15
          newDate.setUTCHours(date.getUTCHours() + minutes / 60, minutes % 60)
          break
        case config.GRANULARITY.HOUR:
          newDate.setUTCHours(date.getUTCHours() + j)
          break
        case config.GRANULARITY.WEEK:
          newDate = new Date(helpDate)
          newDate.setUTCDate(helpDate.getUTCDate() + j * 7)
          newDate.setUTCHours(0)
          break
        default:
          newDate = new Date(
            helpDate.getUTCFullYear(),
            helpDate.getUTCMonth(),
            j + 1
          )
          newDate.setUTCHours(24)
          break
      }
      if (zeroCheck && newDate < startDate) {
        continue
      }
      if (endCheck && newDate > endDate) {
        continue
      }
      row.push({
        date: new Date(newDate),
        count: eventData[newDate.toISOString()]
          ? eventData[newDate.toISOString()][aggregationType]
          : null,
      })
    }
    return row
  }

  const pushRow = (row, dataArray, title) => {
    if (row.length > 0) {
      dataArray.push({
        title: title,
        data: row,
      })
    }
  }

  const data = () => {
    let dataArray = []

    switch (granularity) {
      case config.GRANULARITY.QUARTER:
        let days = dateUtils.getDateDifferenceInDays(startDate, endDate)
        for (let i = 0; i <= days; i++) {
          let day = new Date(startDate)
          day.setDate(day.getDate() + i)

          pushRow(
            createRow(96, day, i === 0, i === days),
            dataArray,
            day.toLocaleDateString("en-US", {
              timeZone: selectedDevice.timezone,
            })
          )
        }
        return dataArray
      case config.GRANULARITY.HOUR:
        let dayDiff = dateUtils.getDateDifferenceInDays(startDate, endDate)
        for (let i = 0; i <= dayDiff; i++) {
          let day = new Date(startDate)
          day.setDate(day.getDate() + i)

          pushRow(
            createRow(24, day, i === 0, i === dayDiff),
            dataArray,
            day.toLocaleDateString("en-US", {
              timeZone: selectedDevice.timezone,
            })
          )
        }
        return dataArray
      case config.GRANULARITY.WEEK:
        const years = dateUtils.getDateDifferenceInYear(startDate, endDate)
        for (let i = 0; i <= years; i++) {
          let year = startDate.getUTCFullYear() + i
          let firstDay = new Date(year, 0, 1)
          firstDay.setUTCDate(firstDay.getUTCDate() - firstDay.getUTCDay())

          pushRow(
            createRow(53, null, i === 0, i === years, firstDay),
            dataArray,
            year
          )
        }
        return dataArray
      default:
        const months = dateUtils.getDateDifferenceInMonths(startDate, endDate)
        for (let i = 0; i <= months; i++) {
          let month = new Date(startDate)
          month.setUTCMonth(startDate.getUTCMonth() + i)
          let days = new Date(
            month.getUTCFullYear(),
            month.getUTCMonth() + 1,
            0
          ).getUTCDate()

          pushRow(
            createRow(days, null, i === 0, i === months, month),
            dataArray,
            `${month.getUTCMonth() + 1}/${month.getUTCFullYear()}`
          )
        }
        return dataArray
    }
  }

  return (
    <Stack>
      <Typography align={"left"} pl={10} variant={"h4"}>
        {granularity === config.GRANULARITY.DAY ||
        granularity === config.GRANULARITY.WEEK
          ? "UTC"
          : selectedDevice.timezone}
      </Typography>
      <GraphContainer
        shouldScroll={shouldScroll}
        onScrollPressed={() => setShouldScroll(!shouldScroll)}
        onAggregationTypeChanged={(value) => setAggregationType(value)}
        onSendRequest={(start, end, gran) => {
          const useUTC =
            gran === config.GRANULARITY.DAY || gran === config.GRANULARITY.WEEK
          const startTZ = useUTC
            ? start
            : DateUtils.overrideTimezoneInfo(start, selectedDevice.timezone)
          const endTZ = useUTC
            ? end
            : DateUtils.overrideTimezoneInfo(end, selectedDevice.timezone)
          setStartDate(startTZ)
          setEndDate(endTZ)
          setGranularity(gran)
          dispatch(
            getEventStats({
              params: {
                home_id: selectedDevice.home_id,
                start: startTZ.toISOString(),
                end: endTZ.toISOString(),
                chunk_size: gran,
                device_id: selectedDevice.device_id,
              },
              id: requestID,
            })
          )
        }}
        isLoading={
          eventStatsResponse.state === config.REQUEST_STATE.PENDING &&
          eventStatsResponse.id === requestID
        }
        isEmpty={
          !fullEventData || fullEventData.length === 0 || data().length === 0
        }
        aggregationType={aggregationType}
      >
        {data().map((row) => {
          return (
            <TableRow style={{ flexGrow: 1 }} key={row.title}>
              <th>{row.title}</th>
              <TableCell style={{ width: "100%" }}>
                <div className={"heatmap-container"} style={style}>
                  {row.data.map((element) => {
                    return (
                      <Rectangle
                        weight={element.count}
                        label={getTooltipDateLabel(element.date)}
                        key={element.date.toLocaleString("en-US", {
                          timeZone: selectedDevice.timezone,
                        })}
                        max={max === 0 ? 1 : max}
                      />
                    )
                  })}
                </div>
              </TableCell>
            </TableRow>
          )
        })}
      </GraphContainer>
    </Stack>
  )
}

HeatmapGrid.propTypes = {
  code: PropTypes.string,
}

export default HeatmapGrid
