import _ from "lodash"
import axios from "axios"
import config from "../config"

export const authRequest = (method, path) => async (arg, thunkAPI) => {
  try {
    const user = thunkAPI.getState().user
    const token = user.idToken
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: token,
      },
    })

    let updatedPath = path
    _.keys(arg?.pathVariables).forEach((key) => {
      updatedPath = updatedPath.replace(key, arg.pathVariables[key])
    })

    const response = await client(updatedPath, {
      method,
      params: arg?.params,
      data: arg?.data,
    })
    return {
      ..._.pick(response, ["status", "statusText", "data"]),
      id: arg?.id,
      isDelete: arg?.isDelete,
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data.error)
  }
}

export const makeAsyncInitialState = (initialState) => ({
  state: null,
  error: null,
  value: initialState,
  id: null,
})

export const makeAsyncReducer = (
  builder,
  action,
  initialState,
  state_key,
  data_retriever = (action) => action.payload.data
) => {
  builder.addCase(action.fulfilled, (state, action) => {
    _.set(
      state,
      action.payload.id
        ? `${state_key}.value[${action.payload.id}]`
        : `${state_key}.value`,
      action.payload.data
    )
    _.set(state, `${state_key}.state`, config.REQUEST_STATE.FULFILLED)
    _.set(
      state,
      `${state_key}.error`,
      _.get(initialState, `${state_key}.error`, null)
    )
    _.set(state, `${state_key}.id`, action.payload.id)
  })
  builder.addCase(action.pending, (state, action) => {
    if (!action.meta.arg?.id && !action.meta.arg?.isDelete) {
      _.set(
        state,
        `${state_key}.value`,
        _.get(initialState, `${state_key}.value`, null)
      )
    }
    _.set(state, `${state_key}.state`, config.REQUEST_STATE.PENDING)
    _.set(
      state,
      `${state_key}.error`,
      _.get(initialState, `${state_key}.error`, null)
    )
    _.set(state, `${state_key}.id`, action.meta.arg?.id)
  })
  builder.addCase(action.rejected, (state, action) => {
    _.set(
      state,
      `${state_key}.value`,
      _.get(initialState, `${state_key}.value`, null)
    )
    _.set(state, `${state_key}.state`, config.REQUEST_STATE.REJECTED)
    _.set(state, `${state_key}.error`, action.payload)
    _.set(state, `${state_key}.id`, action.meta.arg?.id)
  })
}
