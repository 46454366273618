import PropTypes from "prop-types"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import _ from "lodash"

function DeviceState(props) {
  const sortedStates = _.sortBy(props.device.state, (state) => state.event_id)
  return (
    <TableContainer
      sx={{
        maxHeight: "350px",
        boxShadow: 2,
        borderRadius: 5,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant={"h4"}>Event Type</Typography>
            </TableCell>
            <TableCell>
              <Typography variant={"h4"}>Event Value</Typography>
            </TableCell>
            <TableCell>
              <Typography variant={"h4"}>Last Timestamp</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedStates.map((state) => {
            return (
              <TableRow hover key={state.event_id}>
                <TableCell>{state.event_id}</TableCell>
                <TableCell>{state.event_value}</TableCell>
                <TableCell>
                  {new Date(state.last_updated_utc).toLocaleString()}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DeviceState.propTypes = {
  device: PropTypes.object,
}

export default DeviceState
