import config from "../../config"
import { triggerOTA } from "../../redux/api"
import StatefulButton from "../StatefulButton"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import _ from "lodash"
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"

function OTAForm(props) {
  const dispatch = useDispatch()
  const otaResponse = useSelector((state) => state.api.otaResponse)
  const versionsResponse = useSelector((state) => state.api.firmwareVersions)

  const latest = "latest"
  const device_id = props.device.device_id
  const device_type = props.device.device_type

  const getDevices = () => {
    switch (device_type) {
      case config.DEVICE_TYPE.HUB:
        return [config.DEVICE_TYPE.HUB_AUDIOV2, config.DEVICE_TYPE.HUB_VIDEOV2]
      case config.DEVICE_TYPE.SATELLITE:
        return [config.DEVICE_TYPE.SATELLITEV3]
      default:
        return []
    }
  }

  const [currentState, setCurrentState] = useState(
    getDevices().reduce(
      (dict, type) => {
        return { ...dict, [device_id + type]: "" }
      },
      { [device_id]: "" }
    )
  )
  const [selectedVersions, setSelectedVersions] = useState(
    getDevices().reduce((dict, elem) => {
      return { ...dict, [elem]: latest }
    }, {})
  )

  const isVersionSelected = _.values(selectedVersions).some((v) => v !== latest)

  const handleSelect = (type, value) => {
    setSelectedVersions({ ...selectedVersions, [type]: value })
  }

  const getVersionsForSelection = (type) => {
    let versions = versionsResponse.value[type] ?? []
    return versions
  }

  const updateState = (state, currentDeviceID) => {
    if (
      otaResponse.state === state &&
      otaResponse.id === currentDeviceID &&
      otaResponse.state !== currentState[currentDeviceID] &&
      !(
        (otaResponse.state === config.REQUEST_STATE.FULFILLED ||
          otaResponse.state === config.REQUEST_STATE.REJECTED) &&
        otaResponse.id === currentDeviceID &&
        currentState[currentDeviceID] === ""
      )
    ) {
      setCurrentState({ ...currentState, [otaResponse.id]: state })
    }
  }

  const getVersionMetadata = (type, version) => {
    if (version === latest) {
      return versionsResponse.value[type][0]
    }
    return getVersionsForSelection(type).find((v) => v.version === version)
  }

  const checkStates = [
    config.REQUEST_STATE.PENDING,
    config.REQUEST_STATE.FULFILLED,
    config.REQUEST_STATE.REJECTED,
  ]
  checkStates.forEach((state) => {
    updateState(state, device_id)
    getDevices().forEach((type) => {
      updateState(state, device_id + type)
    })
  })

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "230px",
      }}
    >
      <Box>
        {getDevices().map((type, index) => {
          return (
            <Box key={index} pt={2} pb={2}>
              <FormControl size={"small"}>
                <InputLabel id={type}>
                  {config.DEVICE_TYPE_DISPLAY_NAME[type]}
                </InputLabel>
                <Select
                  label={config.DEVICE_TYPE_DISPLAY_NAME[type]}
                  labelId={config.DEVICE_TYPE_DISPLAY_NAME[type]}
                  value={selectedVersions[type]}
                  onChange={(event) => handleSelect(type, event.target.value)}
                >
                  <MenuItem value={latest}>{latest}</MenuItem>
                  {getVersionsForSelection(type).map((version, index) => {
                    return (
                      <MenuItem key={index} value={version.version}>
                        {version.version}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          )
        })}
      </Box>
      {isVersionSelected ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          pt={2}
          pb={2}
        >
          {getDevices().map((type, index) => {
            return (
              <StatefulButton
                key={index}
                requestState={currentState[device_id + type]}
                onResetState={() =>
                  setCurrentState({ ...currentState, [device_id + type]: "" })
                }
                onStartRequest={() => {
                  dispatch(
                    triggerOTA({
                      data: {
                        device_id: device_id,
                        home_id: props.device.home_id,
                        version: getVersionMetadata(
                          type,
                          selectedVersions[type]
                        )?.version,
                        device_type: type,
                        firmware_key: getVersionMetadata(
                          type,
                          selectedVersions[type]
                        )?.key,
                      },
                      id: device_id + type,
                    })
                  )
                }}
                title="Execute OTA"
              />
            )
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <StatefulButton
            requestState={currentState[device_id]}
            onResetState={() =>
              setCurrentState({ ...currentState, [device_id]: "" })
            }
            onStartRequest={() => {
              dispatch(
                triggerOTA({
                  data: {
                    device_id: device_id,
                    home_id: props.device.home_id,
                  },
                  id: device_id,
                })
              )
            }}
            title="Execute OTA"
          />
        </Box>
      )}
    </Box>
  )
}

OTAForm.propTypes = {
  device: PropTypes.object,
}

export default OTAForm
